import * as t from 'io-ts'
import { BaseModelCodec, NamedTypeCodec, nullableType, PagedResultCodec } from './base'
import { RawPointMachinesCodec } from './point-machine.base'
import { TechnicalRoomCodec } from './technical-room'
import { AnalyticsType, AnalyticsTypeMeanPower, AnalyticsTypePowerCurve, AnalyticsTypes } from './analytics-types'

export const TurnoutTypeCodec = t.union([t.literal('undefined'), t.literal('UNDEFINED'), t.literal('3P4W'), t.literal('3p4w')])
export type TurnoutType = t.TypeOf<typeof TurnoutTypeCodec>

export const OperationStateCodec = t.union([t.literal('green'), t.literal('yellow'), t.literal('red')])
export type OperationState = t.TypeOf<typeof OperationStateCodec>

export const ConnectionStateCodec = t.union([t.literal('connected'), t.literal('disconnected')])
export type ConnectionState = t.TypeOf<typeof ConnectionStateCodec>

export const TurnoutFunctionalityTypeTurnout = 'turnout'
export const TurnoutFunctionalityTypeDerail = 'derail'
export const TurnoutFunctionalityTypeCodec = t.union([t.literal(TurnoutFunctionalityTypeTurnout), t.literal(TurnoutFunctionalityTypeDerail)])
export type TurnoutFunctionalityType = t.TypeOf<typeof TurnoutFunctionalityTypeCodec>

export const ConfigurationStatusCodec = t.union([
  t.literal('unfinished'),
  t.literal('waiting_for_approval'),
  t.literal('waiting_for_connection'),
  t.literal('connected'),
  t.literal('undefined'),
])

export const BaseTurnoutCodec = t.intersection([
  NamedTypeCodec,
  t.type({
    active: t.union([t.boolean, t.undefined]),
    configurationStatus: ConfigurationStatusCodec,
    // connectionState: ConnectionStateCodec,
    engineeringId: t.string,
    operationState: OperationStateCodec,
    technicalRoom: t.intersection([BaseModelCodec, NamedTypeCodec]),
    trackPoint: nullableType(t.string),
    turnoutType: TurnoutTypeCodec,
    waypoint: t.string,
    turnoutFunctionalityType: TurnoutFunctionalityTypeCodec,
    analyticsTypeId: t.union([t.number, t.undefined])
  })
])
export const BaseTurnoutsCodec = t.array(BaseTurnoutCodec)
export type BaseTurnout = t.TypeOf<typeof BaseTurnoutCodec>

export const BaseTurnoutWithPmsCodec = t.intersection([
  BaseTurnoutCodec,
  t.type({
    pointMachines: RawPointMachinesCodec,
  }),
])
export type BaseTurnoutWithPms = t.TypeOf<typeof BaseTurnoutWithPmsCodec>

export const RawTurnoutCodec = t.intersection([BaseModelCodec, BaseTurnoutWithPmsCodec])
export const RawTurnoutsCodec = t.array(RawTurnoutCodec)
export type RawTurnout = t.TypeOf<typeof RawTurnoutCodec>

export const TurnoutCodec = t.intersection([
  RawTurnoutCodec,
  t.type({
    _technicalRoom: nullableType(TechnicalRoomCodec),
  }),
])
export const TurnoutsCodec = t.array(TurnoutCodec)
export type Turnout = t.TypeOf<typeof TurnoutCodec>

export const PagedRawTurnoutsCodec = PagedResultCodec(RawTurnoutsCodec)
export type RawPagedTurnouts = t.TypeOf<typeof PagedRawTurnoutsCodec>

export const PagedTurnoutsCodec = PagedResultCodec(TurnoutsCodec)
export type PagedTurnouts = t.TypeOf<typeof PagedTurnoutsCodec>

type State = 'alert' | 'warning' | 'disconnected' | 'healthy'

export const getTurnoutState = (data: Turnout): State => {

  if (data.operationState === 'red') {
    return 'alert'
  }

  if (data.operationState === 'yellow') {
    return 'warning'
  }

  return 'healthy'
}

/**
 * Helper to get the Analytics type
 * @param analyticsId
 * @param analytics
 */
export const getAnalyticsTypeById = (analyticsId:number = -1, analytics:AnalyticsTypes = []) => {
  if(analyticsId && analyticsId !== -1) {
    return analytics?.find((aType) => {
      return aType.id === analyticsId
    })
  }
  return undefined
}

export const getPointMachinesRequired = (analyticsType?:AnalyticsType) => {
  return Boolean(analyticsType && (analyticsType.name === AnalyticsTypePowerCurve || analyticsType.name === AnalyticsTypeMeanPower))
}

export const getAnalyticsTypeEnabled = (analyticsType:AnalyticsType, assetType?:TurnoutFunctionalityType) => {
  // AnalyticsTypePowerCurve and AnalyticsTypeMeanPower are not allowed for derailing devices
  return !Boolean(assetType === TurnoutFunctionalityTypeDerail && (analyticsType.name === AnalyticsTypePowerCurve || analyticsType.name === AnalyticsTypeMeanPower))
}

export const turnoutStateToColorMap: Record<State, string> = {
  alert: 'color-alarm',
  disconnected: 'color-6',
  healthy: 'color-success--active',
  warning: 'color-warning',
}
