import * as React from 'react'
import {
  arrow, autoPlacement,
  autoUpdate,
  FloatingArrow,
  FloatingFocusManager,
  FloatingPortal,
  offset,
  Placement,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useId,
  useInteractions,
  useMergeRefs,
  useRole,
} from '@floating-ui/react'
import { IxButton } from '@siemens/ix-react'
import { ComponentProps, useRef } from 'react'

/**
 * Pass a root element if you have issues w/ z-index
 */
export interface PopoverOptions extends Pick<ComponentProps<typeof FloatingPortal>, 'root'>{
  initialOpen?: boolean
  placement?: Placement
  modal?: boolean
  open?: boolean
  onOpenChange?: (open: boolean) => void;
  arrowRef?:any
  allowedPlacements?:Placement[]
}

const ARROW_HEIGHT = 7;
const GAP = 2;

export function usePopover(
  {
     initialOpen = false,
     placement = 'bottom',
     modal,
     open: controlledOpen,
     onOpenChange: setControlledOpen,
     root,
     allowedPlacements
   }: PopoverOptions = {}
) {
  const [uncontrolledOpen, setUncontrolledOpen] = React.useState(initialOpen)
  const [labelId, setLabelId] = React.useState<string | undefined>()
  const [descriptionId, setDescriptionId] = React.useState<string | undefined>()

  const open = controlledOpen ?? uncontrolledOpen
  const setOpen = setControlledOpen ?? setUncontrolledOpen

  const arrowRef = useRef(null);

  const data = useFloating({
    placement,
    open,
    onOpenChange: setOpen,
    whileElementsMounted: autoUpdate,
    middleware: [
     autoPlacement({
        // top, right, bottom, left
        allowedPlacements: allowedPlacements ?? ['top']
      }),
      offset(ARROW_HEIGHT + GAP),
      /*flip({
        crossAxis: placement.includes('-'),
        fallbackAxisSideDirection: 'end',
        padding: 5,
      }),*/
      shift({ padding: 5 }),
      arrow({
        element: arrowRef,
      }),
    ],
  })

  const context = data.context

  const click = useClick(context, {
    enabled: controlledOpen == null,
  })
  const dismiss = useDismiss(context)
  const role = useRole(context)

  const interactions = useInteractions([click, dismiss, role])

  return React.useMemo(
    () => ({
      open,
      setOpen,
      ...interactions,
      ...data,
      modal,
      labelId,
      descriptionId,
      setLabelId,
      setDescriptionId,
      arrowRef,
      root
    }),
    [open, setOpen, interactions, data, modal, labelId, descriptionId, arrowRef, root],
  )
}

type ContextType =
  | (ReturnType<typeof usePopover> & {
  setLabelId: React.Dispatch<React.SetStateAction<string | undefined>>;
  setDescriptionId: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
})
  | null;

const PopoverContext = React.createContext<ContextType>(null)

export const usePopoverContext = () => {
  const context = React.useContext(PopoverContext)

  if (context == null) {
    throw new Error('Popover components must be wrapped in <Popover />')
  }

  return context
}


/**
 * https://floating-ui.com/docs/react
 * @param children
 * @param modal
 * @param restOptions
 * @constructor
 */
export function Popover(
  {
    children,
    modal = false,
    ...restOptions
  }: {
  children: React.ReactNode;
} & PopoverOptions) {
  // This can accept any props as options, e.g. `placement`,
  // or other positioning options.
  const popover = usePopover({ modal, ...restOptions })
  return (
    <PopoverContext.Provider value={popover}>
      {children}
    </PopoverContext.Provider>
  )
}

interface PopoverTriggerProps {
  /**
   * Text of the button (asChild=false) OR the anchor element (asChild=true)
   */
  children: React.ReactNode;
  /**
   * Allows you to pass any element as trigger.
   * By default, an IxButton is rendered.
   */
  asChild?: boolean;
}

export const PopoverTrigger = React.forwardRef<
  HTMLElement,
  React.HTMLProps<HTMLElement> & PopoverTriggerProps
>(function PopoverTrigger({ children, asChild = false, ...props }, propRef) {
  const context = usePopoverContext()
  const childrenRef = (children as any).ref
  const ref = useMergeRefs([context.refs.setReference, propRef, childrenRef])

  // `asChild` allows the user to pass any element as the anchor
  if (asChild && React.isValidElement(children)) {
    return React.cloneElement(
      children,
      context.getReferenceProps({
        ref,
        ...props,
        ...children.props,
        'data-state': context.open ? 'open' : 'closed',

      }),
    )
  }

  return (
    <IxButton
      ref={ref}
      type="button"
      data-state={context.open ? 'open' : 'closed'}
      {...context.getReferenceProps(props)}
    >
      {children}
    </IxButton>
  )
})

export const PopoverContent = React.forwardRef<
  HTMLDivElement,
  React.HTMLProps<HTMLDivElement>
>(

  function PopoverContent({ style, ...props }, propRef) {
    const { context: floatingContext, arrowRef, root, ...context } = usePopoverContext()
    const ref = useMergeRefs([context.refs.setFloating, propRef])

    if (!floatingContext.open) return null

    return (
      <FloatingPortal root={root}>
        <FloatingFocusManager context={floatingContext} modal={context.modal}>
          <div
            ref={ref}
            className={'popover-content'}
            style={{ ...context.floatingStyles, ...style }}
            aria-labelledby={context.labelId}
            aria-describedby={context.descriptionId}
            {...context.getFloatingProps(props)}
          >
            {props.children}
            <FloatingArrow className={"popover-arrow"} ref={arrowRef} context={floatingContext} />
          </div>
        </FloatingFocusManager>
      </FloatingPortal>
    )
  }
)

export const PopoverHeading = React.forwardRef<
  HTMLHeadingElement,
  React.HTMLProps<HTMLHeadingElement>
>(function PopoverHeading(props, ref) {
  const { setLabelId } = usePopoverContext()
  const id = useId()

  // Only sets `aria-labelledby` on the Popover root element
  // if this component is mounted inside it.
  React.useLayoutEffect(() => {
    setLabelId(id)
    return () => setLabelId(undefined)
  }, [id, setLabelId])

  return (
    <h2 {...props} ref={ref} id={id}>
      {props.children}
    </h2>
  )
})

export const PopoverDescription = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLProps<HTMLParagraphElement>
>(function PopoverDescription(props, ref) {
  const { setDescriptionId } = usePopoverContext()
  const id = useId()

  // Only sets `aria-describedby` on the Popover root element
  // if this component is mounted inside it.
  React.useLayoutEffect(() => {
    setDescriptionId(id)
    return () => setDescriptionId(undefined)
  }, [id, setDescriptionId])

  return <p {...props} ref={ref} id={id} />
})

export const PopoverClose = React.forwardRef<
  HTMLButtonElement,
  React.ButtonHTMLAttributes<HTMLIxButtonElement>
>(function PopoverClose(props) {
  const { setOpen } = usePopoverContext()

  return (
    <IxButton
      {...props}
      type={'button'}
      onClick={(event) => {
        props.onClick?.(event)
        setOpen(false)
      }}
    >
      Okay
    </IxButton>
  )
})
