import { IxTile } from '@siemens/ix-react'
import { TurnoutHealthStatesFilterOpts } from 'api'
import DataFilter, { Filter, SearchField } from 'components/DataFilter'
import HealthStateChart from 'components/HealthStateChart'
import { useFilterOpts } from 'hooks'
import { DateTime } from 'luxon'
import { Turnout } from 'models'
import { JSX, useState } from 'react'
import { useTranslation } from 'react-i18next'

const to = DateTime.now()

const searchFields: SearchField[] = [
  {
    path: 'from-to',
    type: 'date_range',
  },
]

const defaultFilters: Filter[] = [{ ...searchFields[0], value: { from: DateTime.now().minus({ days: 2 }), to } }]

export interface TurnoutHealthTileProps {
  turnout: Turnout
}

export function TurnoutHealthTile({ turnout }: TurnoutHealthTileProps): JSX.Element {
  const [filters, setFilters] = useState<Filter[]>(defaultFilters)
  const { t } = useTranslation()
  const filterOpts = useFilterOpts<Partial<TurnoutHealthStatesFilterOpts>>(filters)

  return (
    <IxTile>
      <div slot="header">{t('Health status history')}</div>

      <div className="d-flex flex-grow-1 justify-content-end">
        <DataFilter fields={searchFields} onChange={setFilters} value={filters} removeGrid />
      </div>

      <div>
        <HealthStateChart filterOpts={filterOpts} turnout={turnout} />
      </div>
    </IxTile>
  )
}
