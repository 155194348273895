import { QueryPointMachineEventsFilterOpts } from 'api'
import { useMainProject } from 'hooks'
import { SWRResponse, useSWR } from 'lib/swr'
import { IdType, PointMachine, PointMachineEvent, Turnout } from 'models'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Key } from 'swr'
import {
  PointMachineEventWithEstimatedWeatherDataApiClient
} from '../../api/point-machine-event-with-estimated-weather-data'

interface PointMachineEventsOfTurnout {
  content: PointMachineEvent[]
  warning?: string
}

export const getQueryPointMachineEventsOfTurnoutKey = (
  turnout: Parameters<typeof useQueryPointMachineEventsOfTurnout>[0],
  opts: Parameters<typeof useQueryPointMachineEventsOfTurnout>[2],
  parentId?: IdType,
): Key => {
  if (parentId == null || turnout == null) {
    return null
  }

  return ['useQueryPointMachineEventsOfTurnout', parentId, turnout.id, JSON.stringify(opts)]
}

export const useQueryPointMachineEventsOfTurnout = (
  turnout: Turnout | undefined,
  pointMachines: PointMachine[] | undefined,
  opts?: Partial<QueryPointMachineEventsFilterOpts> | null,
): SWRResponse<PointMachineEventsOfTurnout> => {
  const { data: mainProject } = useMainProject()
  const { t } = useTranslation()

  const swrResponse = useSWR(getQueryPointMachineEventsOfTurnoutKey(turnout, opts, mainProject?.id), async () => {
    const promises = turnout!.pointMachines.map(async (pm) =>
      PointMachineEventWithEstimatedWeatherDataApiClient.query(mainProject!.id, {
        ...opts,
        engineeringId: pm.engineeringId,
      }),
    )

    const responses = await Promise.all(promises)

    const warning = responses.some((entry) => entry.data.totalPages > 1)
      ? 'Maximum number of entries exceeded. Not all entries are being displayed.'
      : undefined

    const data: PointMachineEventsOfTurnout = {
      content: responses.flatMap((entry) => entry.data.content),
      warning,
    }

    return {
      data,
      dateTime: responses[0].dateTime,
    }
  })

  const data = useMemo<PointMachineEventsOfTurnout | undefined>(() => {
    if (swrResponse.data == null) {
      return undefined
    }

    const content = swrResponse.data.content
      .filter((entry) => pointMachines?.find((pm) => pm.id === entry.pointMachineId) != null)
      .map((entry) => ({
        ...entry,
        pointMachine: pointMachines?.find((pm) => pm.id === entry.pointMachineId),
      }))

    const warning = swrResponse.data.warning == null ? undefined : t(swrResponse.data.warning)

    return { ...swrResponse.data, content, warning }
  }, [pointMachines, swrResponse.data, t])

  return useMemo(() => {
    return {
      ...swrResponse,
      data,
    }
  }, [data, swrResponse])
}
