import Table, { TableProps } from 'components/Table'
import { Turnout } from 'models'
import { JSX } from 'react'
import { useTranslation } from 'react-i18next'
import ActionsCell from './components/ActionsCell'
import StateEnabledItem from '../StateEnabledItem'

export interface TurnoutConfigTableProps extends TableProps<Turnout> {
  data?: Turnout[]
}

export function TurnoutConfigTable({ data, ...rest }: TurnoutConfigTableProps): JSX.Element {
  const { t } = useTranslation()

  return (
    <Table
      {...rest}
      columnDefs={[
        {
          field: 'name',
          headerName: t('Name') ?? undefined,
          initialSort: 'asc',
          sortable: true,
          unSortIcon: true,
          minWidth: 200,
          maxWidth: 500,
          flex: 2
        },
        {
          field: 'engineeringId',
          headerName: 'Asset ID',
          sortable: true,
          unSortIcon: true,
          minWidth: 150,
          maxWidth: 200,
        },
        {
          colId: 'technicalRoomInterlockingName',
          field: '_technicalRoom._interlocking.name',
          headerName: 'Interlocking',
          sortable: true,
          unSortIcon: true,
          minWidth: 150,
          maxWidth: 200,
        },
        {
          field: 'turnoutFunctionalityType',
          headerName: t('models.turnout.turnoutFunctionalityType') ?? undefined,
          sortable: true,
          unSortIcon: true,
          width: 125,
          valueGetter: ({ data }) =>
            data == null ? undefined : t(`models.turnout.turnoutFunctionalityType-${data?.turnoutFunctionalityType}`),
        },
        {
          field: 'active',
          headerName: t('models.turnout.active') ?? undefined,
          headerClass: 'text-center',
          sortable: true,
          unSortIcon: true,
          width: 116,
          minWidth: 116,
          cellRenderer: ({ data }: any) => (
            <StateEnabledItem
              id={`${data.id}-active-state`}
              enabled={data.active}
            />
          ),
        },
        {
          cellRenderer: ActionsCell,
          width: 100,
          field: 'id',
          headerName: t('Actions') ?? undefined,
        },
      ]}
      rowData={data}
      selectable
      sortingOrder={['desc', 'asc']}
    />
  )
}
