import { IxButton } from '@siemens/ix-react'
import ContextWrapper from 'components/ContextWrapper'
import Modal, { ModalContent } from 'components/Modal'
import UpdatePointMachineForm from 'components/UpdatePointMachineForm'
import UpdateTurnoutForm, { UpdateTurnoutFormProps } from 'components/UpdateTurnoutForm'
import { useAnalytics, useTurnout } from 'hooks'
import * as routes from 'lib/routes'
import { getAnalyticsTypeById, getPointMachinesRequired, IdType, Turnout } from 'models'
import React, { JSX, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate } from 'react-router-dom'
import AnalyticsTypeInfo from '../AnalyticsTypeInfo/AnalyticsTypeInfo'

type FormDataWrapperProps = Pick<UpdateTurnoutFormProps, 'onAfterSubmit' | 'onCancel'> &
  Pick<UpdateTurnoutModalProps, 'turnoutId' | 'projectId'>

function FormDataWrapper({ turnoutId, projectId, ...rest }: FormDataWrapperProps): JSX.Element {
  const { t } = useTranslation()
  const [isDeleting, setIsDeleting] = useState(false)

  const { data: turnout, ...turnoutData } = useTurnout(isDeleting ? undefined : turnoutId)
  const { data: analytics, ...analyticsData } = useAnalytics(projectId)
  const [updatedTurnout, setUpdatedTurnout] = useState<Turnout |undefined>(undefined)

  const [isCreatingPointMachine, setIsCreatingPointMachine] = useState(false)


  useEffect(() => {
    // Set initial values
    if(turnout && !updatedTurnout) setUpdatedTurnout(turnout)
  }, [turnout, updatedTurnout, setUpdatedTurnout])

  /**
   * Disables save if
   * ai-curve is selected for a derail asset
   */
  const savingDisabled = useMemo<boolean>(() => {
    let isDisabled = false
    if(analytics && updatedTurnout) {
      const analyticsType = getAnalyticsTypeById(updatedTurnout.analyticsTypeId, analytics)
      if(updatedTurnout && updatedTurnout.turnoutFunctionalityType && analyticsType) {
        isDisabled = getPointMachinesRequired(analyticsType)
      }
    }
    return isDisabled
  }, [updatedTurnout, analytics])

  /**
   * Called after creating, updating or deleting a point machine
   */
  const afterPointMachineUpdate = () => {
    setIsCreatingPointMachine.bind(null, false)
    if(!isDeleting) {
      // Reload connected turnout
      turnoutData.mutate()
    }
  }

  const handleBeforeDelete = () => {
    setIsDeleting(true)
  }

  return (
    <ContextWrapper data={turnout} error={turnoutData.error || analyticsData.error} isLoading={turnoutData.isLoading || analyticsData.isLoading}>
      {(turnout) => (
        <>

          <UpdateTurnoutForm turnout={turnout} analytics={analytics} onUpdate={setUpdatedTurnout} savingDisabled={savingDisabled} onBeforeDelete={handleBeforeDelete} {...rest}>

            <hr />

            {(turnout.pointMachines.length > 0 || isCreatingPointMachine) && (
              <div className="mt-2 h5">{t('Edit point machines')}</div>
            )}

            {updatedTurnout && turnout.pointMachines.length === 0 && <AnalyticsTypeInfo type={'message'} selectedId={updatedTurnout.analyticsTypeId} analytics={analytics} />}

            {turnout.pointMachines.map((pm) => (
              <div key={pm.id}>
                <UpdatePointMachineForm pointMachine={pm} turnout={turnout} onAfterSubmit={afterPointMachineUpdate} />
                <hr />
              </div>
            ))}

            {isCreatingPointMachine ? (
              <div className={'ms-0'}>
                <UpdatePointMachineForm
                  onAfterSubmit={afterPointMachineUpdate}
                  onCancel={setIsCreatingPointMachine.bind(null, false)}
                  turnout={turnout}
                />
              </div>
            ) : (
              <div className="d-flex justify-content-between mb-4">
                <div/>
                <IxButton outline onClick={setIsCreatingPointMachine.bind(null, true)}>
                  {t('Create point machine')}
                </IxButton>
              </div>
            )}

          </UpdateTurnoutForm>

        </>

      )}
    </ContextWrapper>
  )
}

export interface UpdateTurnoutModalProps {
  turnoutId: IdType | undefined
  visible: boolean
  projectId:IdType
}

export function UpdateTurnoutModal({ projectId, turnoutId, visible }: UpdateTurnoutModalProps): JSX.Element {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <Modal title={t(`routes.${routes.editAssetConfigurationRoute.name}`)} visible={visible}>
      <ModalContent
        cancelButton={null}
        okButton={null}
        inlineFooter
        onCancel={() => navigate(generatePath(routes.assetConfigurationRoute.pathname))}
        title={t(`routes.${routes.editAssetConfigurationRoute.name}`)}
      >
        <FormDataWrapper
          onAfterSubmit={() => navigate(generatePath(routes.assetConfigurationRoute.pathname))}
          onCancel={() => navigate(generatePath(routes.assetConfigurationRoute.pathname))}
          turnoutId={turnoutId}
          projectId={projectId}
        />
      </ModalContent>
    </Modal>
  )
}
