import { IdType, PointMachineEvent } from 'models'
import { ApiClientResponse, BaseApiClient } from './base'
import { DateRangeFilterOpts, Methods } from './utils'
import { SensorMeasurements, SensorMeasurementsCodec } from '../models/sensor-measurements'

export interface SensorMeasurementFilterOpts extends DateRangeFilterOpts, Pick<PointMachineEvent, 'direction'> {

}

/**
 * Load and parse /environment-sensor-measurements
 */
class SensorMeasurementApiClient extends BaseApiClient {
  async measurements(
    turnoutId: IdType,
    opts: Partial<SensorMeasurementFilterOpts> | null | undefined,
    signal?: AbortSignal,
  ): Promise<ApiClientResponse<SensorMeasurements>> {
    const path = [this.baseUrl, this.endpointUrl, turnoutId, 'environment-sensor-measurements'].join('/')

    const { data, ...rest } = await this.request(path, Methods.GET, undefined, opts, signal)
    return { ...rest, data: await this.decode(data, SensorMeasurementsCodec) }
  }
}

const instance = new SensorMeasurementApiClient('turnouts')

export { instance as SensorMeasurementApiClient }
