import { ProjectApiClient, ProjectConfigurationApiClient } from 'api'
import { SWRResponse, useSWR } from 'lib/swr'
import {
  AnalyticsTypes,
  IdType,
  Project,
} from 'models'
import { useMemo } from 'react'
import { Key } from 'swr'

export const getProjectsKey = (): Key => 'useProjects'

export const useProjects = (): SWRResponse<Project[]> => {
  return useSWR(getProjectsKey(), async () => {
    return ProjectApiClient.list()
  })
}

export const getProjectKey = (id: Parameters<typeof useProject>[0]): Key => {
  if (id == null) {
    return null
  }

  return ['useProject', id]
}

export const useProject = (id: IdType | undefined | null): SWRResponse<Project> => {
  return useSWR(getProjectKey(id), () => {
    return ProjectApiClient.read(id!)
  })
}

export const useAnalytics = (id:IdType): SWRResponse<AnalyticsTypes> => {
  return useSWR(`${getProjectKey(id)}-analyticsTypes`, () => {
    return ProjectConfigurationApiClient.getAnalyticsTypes(id)
  })
}

export const useMainProject = (): SWRResponse<Project> => {
  const { data: projects, ...projectsData } = useProjects()

  const data = useMemo(() => {
    // TODO Improve the selection of the main project (SPMO-675)
    return projects?.find((project) => project.name.toLowerCase().startsWith('spm')) ?? projects?.[0]
  }, [projects])

  return useMemo(() => {
    return {
      ...projectsData,
      data,
      mutate: async () => {
        await projectsData.mutate()
        return undefined
      },
    }
  }, [data, projectsData])
}
