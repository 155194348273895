import { IxTabItem, IxTabs, IxTile } from '@siemens/ix-react'
import { QueryPointMachineEventsFilterOpts, TurnoutTrendCurveFilterOpts } from 'api'
import { DataPoint } from 'components/ConcatenatedIncidentChart'
import ContextWrapper from 'components/ContextWrapper'
import DataFilter, { Filter, SearchField } from 'components/DataFilter'
import { useFilterOpts, usePointMachines, useTurnout, useTurnoutTrendCurve } from 'hooks'
import { DateTime } from 'luxon'
import { IdType } from 'models'
import React, { JSX, useCallback, useEffect, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Encoding } from 'vega-lite/build/src/encoding'
import { AnyMark } from 'vega-lite/build/src/mark'
import { GenericUnitSpec } from 'vega-lite/build/src/spec'
import FitnessGraph from './components/FitnessGraph'
import MeanPowerDurationGraph from './components/MeanPowerDurationGraph'
import MeanPowerTrendGraph from './components/MeanPowerTrendGraph'
import PointMachineEventFilters from './components/PointMachineEventFilters'
import PointMachineEventTable, { PointMachineEventTableProps } from './components/PointMachineEventTable'
import PowerGraph from './components/PowerGraph'
import { useQueryPointMachineEventsOfTurnout } from './hooks'
import { WeatherGraph } from './components/WeatherGraph/WeatherGraph'
import { useSensorMeasurements } from '../../hooks/use-sensor-measurements'
import { SensorMeasurementFilterOpts } from '../../api/sensor-measurements'
import MessageBar from '../MessageBar/MessageBar'
import { getDateRangeDefaults } from '../DataFilter/utils'

const to = DateTime.now()

const dateSearchFields: SearchField[] = [
  {
    path: 'from-to',
    type: 'date_range',
  },
]

const defaultFilters: Filter[] = [{ ...dateSearchFields[0], value: getDateRangeDefaults('last-3d') }]

export interface PointMachineEventChartsProps {
  turnoutId: IdType
}

export function PointMachineEventCharts({ turnoutId }: PointMachineEventChartsProps): JSX.Element {
  const [dateFilters, setDateFilters] = useState<Filter[]>(defaultFilters)
  const [eventFilters, setEventFilters] = useState<Filter[]>()
  const [selectedIds, setSelectedIds] = useState<number[]>([])
  const [tab, setTab] = useState(0)
  const { data: turnout } = useTurnout(turnoutId)
  const { t } = useTranslation()

  const filters = useMemo(() => {
    if (eventFilters == null) {
      return undefined
    }

    return [...eventFilters, ...dateFilters]
  }, [dateFilters, eventFilters])

  const { data: pointMachines, ...pointMachinesData } = usePointMachines(turnoutId)

  const turnoutTrendCurveFilterOpts = useFilterOpts<Partial<TurnoutTrendCurveFilterOpts>>(
    filters,
    filters == null ? undefined : { to },
  )

  const { data: trendCurve, ...trendCurveData } = useTurnoutTrendCurve(turnoutId, turnoutTrendCurveFilterOpts)

  /**
   * Sensor data
   */
  const sensorMeasurementsFilterOpts = useFilterOpts<Partial<SensorMeasurementFilterOpts>>(
    filters,
    filters == null ? undefined : { to },
  )
  // Load sensor data
  const { data: sensorMeasurements, ...sensorMeasurementsData } = useSensorMeasurements(turnoutId, sensorMeasurementsFilterOpts)


  const filteredPointMachines = useMemo(() => {
    const filtered = pointMachines?.filter((pm) => {
      const filter = filters?.find((filter) => filter.path === 'pointMachineName')
      const value =
        typeof filter?.value === 'string' ? filter.value.split(',') : Array.isArray(filter?.value) ? filter!.value : []

      if (filter == null || value.length === 0) {
        return true
      }

      return value.includes(pm.name)
    })

    return filtered
  }, [filters, pointMachines])




  const pointMachineEventFilterOpts = useFilterOpts<Partial<QueryPointMachineEventsFilterOpts>>(
    turnout == null ? undefined : filters,
    // 'size' limits the amount of entries. A value bigger than 200 leads to performance issues.
    // If the limit is reached, a message bar will be displayed above the table.
    turnout == null ? undefined : { sortBy: 'start', sortingDirection: 'desc', size: 200 },
  )

  const { data: pointMachineEvents, ...pointMachineEventsData } = useQueryPointMachineEventsOfTurnout(
    turnout,
    filteredPointMachines,
    pointMachineEventFilterOpts,
  )

  useEffect(() => {
    setSelectedIds((current) => {
      return current.filter((id) => pointMachineEvents?.content.find((pme) => pme.id === id))
    })
  }, [pointMachineEvents])

  const visiblePointMachineEvents = useMemo(() => {
    return selectedIds.length > 0 ? pointMachineEvents?.content.filter((pme) => selectedIds.find((id) => id === pme.id)) : pointMachineEvents?.content
  }, [pointMachineEvents, selectedIds])

  const onSelectPointMachineEvents = useCallback<NonNullable<PointMachineEventTableProps['onSelectionChanged']>>(
    (event) => {
      setSelectedIds(event.api.getSelectedRows().map((item) => item.id))
    },
    [],
  )

  const powerData = useMemo((): DataPoint[] => {
    return (visiblePointMachineEvents ?? []).flatMap((pmEvent): DataPoint => {
      return {
        start: pmEvent.start,
        type: t(`models.event.direction-${pmEvent.direction}`),
        type2: pmEvent.pointMachine?.name,
        value: pmEvent.meanPower,
      }
    })
  }, [t, visiblePointMachineEvents])

  const maxPower = useMemo<number>(() => {
    const maxMeanPower = powerData.reduce((max, data) => {
      return typeof data.value === 'number' ? Math.max(max, data.value) : max
    }, 0)

    const maxTrendPower = Object.values(trendCurve?.trendsByDirection ?? {}).reduce((max, trendByDirection) => {
      if (trendByDirection.mean_power == null) {
        return max
      }

      return Math.max(max, ...Object.values(trendByDirection.mean_power))
    }, 0)

    return Math.max(maxMeanPower, maxTrendPower) + 20
  }, [trendCurve, powerData])

  const powerLayer = useMemo((): GenericUnitSpec<Encoding<string>, AnyMark> => {
    const encoding: Encoding<string> = {
      color: {
        field: 'type',
        scale: { range: ['var(--theme-chart-7)', 'var(--theme-chart-13)'] },
        title: t('models.event.meanPower'),
      },
      opacity: { condition: { param: 'highlightPower', value: 0.9 }, value: 0.2 },
      tooltip: [
        { field: 'start', format: '%c', title: t('Timestamp'), type: 'temporal' },
        { field: 'type', title: t('models.event.direction') },
        { field: 'type2', title: t('Point machine') },
        { field: 'value', title: `${t('models.event.meanPower')} [W]`, type: 'quantitative' },
      ],
      y: {
        field: 'value',
        /*scale: { domain: [0, maxPower] },*/ title: `${t('models.event.meanPower')} [W]`,
        type: 'quantitative',
      },
    }

    return {
      data: { values: powerData },
      encoding,
      mark: { size: 80, type: 'circle' },
      params: [
        // highlight
        {
          bind: 'legend',
          name: 'highlightPower',
          select: { type: 'point', fields: ['type'] },
        },
      ],
    }
  }, [powerData, t])

  return (
    <IxTile>
      <div slot="header">{t('Performance data')}</div>

      <div>
        <ContextWrapper data={pointMachines} error={pointMachinesData.error} isLoading={pointMachinesData.isLoading}>
          {(pointMachines) => (
            <>
              <div className="row justify-content-between align-items-center mt-4 g-2">
                <div className="col-auto">
                  <PointMachineEventFilters
                    onChange={setEventFilters}
                    pointMachines={pointMachines.sort((a, b) => (a.position - b.position))}
                    value={eventFilters}
                  />
                </div>
              </div>

              <div className="row justify-content-end align-items-center mx-1 mt-1">
                <DataFilter fields={dateSearchFields} onChange={setDateFilters} value={dateFilters} removeGrid />
              </div>

              {pointMachineEvents?.warning != null &&
                <div className={'mx-3'}>
                <MessageBar type="warning" dismissible={false}>
                  <Trans
                    components={{
                      br: <br />,
                      b: <strong />,
                    }}
                    i18nKey={pointMachineEvents?.warning}
                  />
                </MessageBar>
                </div>
              }

              <div className="mt-4">
                <IxTabs selected={tab}>
                  <IxTabItem onClick={setTab.bind(null, 0)}>{t('Mean power & trend')}</IxTabItem>
                  <IxTabItem onClick={setTab.bind(null, 1)}>{t('Turn time')}</IxTabItem>
                  <IxTabItem onClick={setTab.bind(null, 2)}>{t('Fitness score')}</IxTabItem>
                  <IxTabItem onClick={setTab.bind(null, 3)}>{t('Power curves')}</IxTabItem>
                  <IxTabItem onClick={setTab.bind(null, 4)}>{t('Weather data')}</IxTabItem>
                </IxTabs>

                {tab === 0 && (
                  <ContextWrapper data={trendCurve} error={trendCurveData.error} isLoading={trendCurveData.isLoading}>
                    {(trendCurve) => (
                      <MeanPowerTrendGraph
                        maxPower={maxPower}
                        turnoutTrendCurve={trendCurve}
                        powerLayer={powerLayer}
                        filterOpts={pointMachineEventFilterOpts}
                      />
                    )}
                  </ContextWrapper>
                )}

                {tab === 1 && (
                  <ContextWrapper
                    data={visiblePointMachineEvents}
                    error={pointMachineEventsData.error}
                    isLoading={pointMachineEventsData.isLoading}
                  >
                    {(pointMachineEvents) => (
                      <MeanPowerDurationGraph
                        powerLayer={powerLayer}
                        pointMachineEvents={pointMachineEvents}
                        filterOpts={pointMachineEventFilterOpts}
                      />
                    )}
                  </ContextWrapper>
                )}

                {tab === 2 && (
                  <ContextWrapper
                    data={visiblePointMachineEvents}
                    error={pointMachineEventsData.error}
                    isLoading={pointMachineEventsData.isLoading}
                  >
                    {(pointMachineEvents) => (
                      <FitnessGraph pointMachineEvents={pointMachineEvents} filterOpts={pointMachineEventFilterOpts} />
                    )}
                  </ContextWrapper>
                )}

                {tab === 3 && (
                  <ContextWrapper
                    data={visiblePointMachineEvents}
                    error={pointMachineEventsData.error}
                    isLoading={pointMachineEventsData.isLoading}
                  >
                    {(pointMachineEvents) => <PowerGraph pointMachineEvents={pointMachineEvents} />}
                  </ContextWrapper>
                )}

                {tab === 4 && (
                  <ContextWrapper
                    data={sensorMeasurements}
                    error={sensorMeasurementsData.error}
                    isLoading={sensorMeasurementsData.isLoading}
                  >
                    {(sensorMeasurements) => <WeatherGraph powerLayer={powerLayer} measurements={sensorMeasurements} filterOpts={sensorMeasurementsFilterOpts} />}
                  </ContextWrapper>
                )}
              </div>

              <div className="mt-4">
                <PointMachineEventTable
                  onSelectionChanged={onSelectPointMachineEvents}
                  pointMachineEvents={pointMachineEvents?.content ?? []}
                  selectedIds={selectedIds}
                />
              </div>
            </>
          )}
        </ContextWrapper>
      </div>
    </IxTile>
  )
}
