import ContextWrapper from 'components/ContextWrapper'
import Modal, { ModalContent } from 'components/Modal'
import UpdateTurnoutForm, { UpdateTurnoutFormProps } from 'components/UpdateTurnoutForm'
import { useAnalytics, useTurnouts } from 'hooks'
import * as routes from 'lib/routes'
import { IdType } from 'models'
import { JSX } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate } from 'react-router-dom'

type FormDataWrapperProps = Pick<UpdateTurnoutFormProps, 'onAfterSubmit' | 'onCancel' | 'onRemoveItem'> &
  Pick<UpdateTurnoutsModalProps, 'turnoutIds' | 'projectId'>

function FormDataWrapper({ turnoutIds, projectId,  ...rest }: FormDataWrapperProps): JSX.Element {
  const { data: turnouts, ...turnoutsData } = useTurnouts(null, turnoutIds)
  const { data: analytics, ...analyticsData } = useAnalytics(projectId)

  return (
    <ContextWrapper data={turnouts} error={turnoutsData.error || analyticsData.error} isLoading={turnoutsData.isLoading || analyticsData.isLoading}>
      {(turnouts) => <UpdateTurnoutForm turnout={turnouts} analytics={analytics} {...rest} />}
    </ContextWrapper>
  )
}

export interface UpdateTurnoutsModalProps {
  turnoutIds: IdType[] | undefined
  visible: boolean
  projectId:IdType
}

export function UpdateTurnoutsModal({ turnoutIds, projectId, visible }: UpdateTurnoutsModalProps): JSX.Element {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <Modal title={t('Bulk edit turnouts')} visible={visible}>
      <ModalContent
        cancelButton={null}
        okButton={null}
        onCancel={() => navigate(generatePath(routes.assetConfigurationRoute.pathname))}
        title={t('Bulk edit turnouts')}
        inlineFooter
      >
        <FormDataWrapper
          onAfterSubmit={() => navigate(generatePath(routes.assetConfigurationRoute.pathname))}
          onCancel={() => navigate(generatePath(routes.assetConfigurationRoute.pathname))}
          onRemoveItem={(_, turnouts) => {
            if (turnouts == null) {
              return
            }

            if (turnouts.length === 0) {
              return navigate(generatePath(routes.assetConfigurationRoute.pathname))
            }

            navigate(
              generatePath(routes.editAssetConfigurationRoute.pathname, {
                assetId: turnouts.map((turnout) => turnout.id).join(','),
              }),
            )
          }}
          turnoutIds={turnoutIds}
          projectId={projectId}
        />
      </ModalContent>
    </Modal>
  )
}
