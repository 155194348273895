import { IxPagination, IxSpinner } from '@siemens/ix-react'
import { JSX as IxJSX } from '@siemens/ix/dist/types/components'
import { GridOptions } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import Empty, { EmptyProps } from 'components/Empty'
import ErrorInfo from 'components/ErrorInfo'
import { BaseModel } from 'models'
import { CSSProperties, ForwardedRef, JSX, RefCallback, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import * as S from './styles'

export interface TableProps<T extends BaseModel> extends GridOptions<T> {
  className?: string
  emptyProps?: EmptyProps
  error?: Error
  gridRef?: ForwardedRef<AgGridReact<T>>
  isLoading?: boolean
  paginationProps?: Partial<IxJSX.IxPagination> | false
  selectable?: boolean | { columnIndex: number }
  selectedIds?:number[],
  style?: CSSProperties
}

export function Table<T extends BaseModel>({
  className,
  emptyProps,
  error,
  gridRef,
  isLoading,
  paginationPageSize = 15,
  paginationProps,
  selectable,
  style,
  onSelectionChanged,
  selectedIds,
  ...props
}: TableProps<T>): JSX.Element {
  const ref = useRef<AgGridReact<T> | null>(null)
  const [currentPage, setCurrentPage] = useState(0)

  const columnDefs = useMemo<GridOptions<T>['columnDefs']>(() => {
    if (props.columnDefs == null || !selectable) {
      return props.columnDefs
    }

    const exportableColumnIndex = typeof selectable === 'boolean' ? 0 : selectable.columnIndex

    return [
      ...props.columnDefs.slice(0, exportableColumnIndex),
      {
        ...props.columnDefs[exportableColumnIndex],
        checkboxSelection: true,
        headerCheckboxSelection: true,
      },
      ...props.columnDefs.slice(exportableColumnIndex + 1),
    ]
  }, [selectable, props.columnDefs])

  const pageCount = useMemo(() => {
    return props.rowData == null ? undefined : Math.ceil(props.rowData.length / paginationPageSize)
  }, [paginationPageSize, props.rowData])

  useEffect(() => {
    if (isLoading) {
      return ref.current?.api?.showLoadingOverlay()
    }

    if (props.rowData != null && props.rowData.length === 0) {
      return ref.current?.api?.showNoRowsOverlay()
    }

    ref.current?.api?.hideOverlay()
  }, [isLoading, props.rowData])

  useEffect(() => {
    ref.current?.api?.forEachNode((node) => {
      node.setSelected(Boolean((selectedIds ?? []).find((id) => id === node.data?.id)))
    })
  }, [selectedIds, props.rowData])

  const onSelectionChangedInternal = useCallback<NonNullable<TableProps<T>['onSelectionChanged']>>(
    (event) => {
      if (event.source.startsWith('api')) {
        return
      }
      onSelectionChanged?.(event)
    },
    [onSelectionChanged],
  )

  useEffect(() => {
    // Reset selection and page on data changes
    // if(props.rowData) ref.current?.api?.deselectAll()
    setCurrentPage(0)
  }, [props.rowData])

  const loadingOverlay = useCallback(() => <IxSpinner />, [])
  const noRowsOverlay = useCallback(() => <Empty {...emptyProps} />, [emptyProps])

  const onPageSelected = useCallback<NonNullable<IxJSX.IxPagination['onPageSelected']>>((event) => {
    const newPage = event.detail
    setCurrentPage(newPage)
    ref.current?.api.paginationGoToPage(event.detail)
  }, [])

  const onRef = useCallback<RefCallback<AgGridReact<T>>>(
    (element) => {
      ref.current = element

      if (gridRef == null) {
        return
      }

      if (typeof gridRef === 'function') {
        return gridRef(element)
      }

      gridRef.current = element
    },
    [gridRef],
  )

  if (error != null) {
    return <ErrorInfo error={error} />
  }

  return (
    <S.TableWrapper className={`ag-theme-alpine-dark ag-theme-ix ${className ?? ''}`} style={style}>
      <AgGridReact
        animateRows
        domLayout="autoHeight"
        loadingOverlayComponent={loadingOverlay}
        noRowsOverlayComponent={noRowsOverlay}
        onSelectionChanged={onSelectionChangedInternal}
        pagination
        paginationPageSize={paginationPageSize}
        ref={onRef}
        rowSelection={Boolean(selectable) ? 'multiple' : undefined}
        suppressMovableColumns
        suppressPaginationPanel
        suppressRowClickSelection
        {...props}
        columnDefs={columnDefs}

      />

      <div className="d-flex justify-content-end">
        {paginationProps !== false && props.rowData != null && props.rowData.length > 0 && (
          <IxPagination
            count={pageCount}
            itemCount={paginationPageSize}
            onPageSelected={onPageSelected}
            showItemCount
            {...paginationProps}
            selectedPage={currentPage}
          />
        )}
      </div>
    </S.TableWrapper>
  )
}