import React, { FC, PropsWithChildren, ReactElement, useCallback } from 'react'
import UpdatePointMachineForm from '../../../../components/UpdatePointMachineForm'
import { AnalyticsTypes, getAnalyticsTypeById, getPointMachinesRequired, IdType, Turnout } from 'models'
import { useTranslation } from 'react-i18next'
import { useTurnout, useUpdateTurnouts } from 'hooks'
import { ContextWrapper } from '../../../../components/ContextWrapper'
import ErrorInfo from '../../../../components/ErrorInfo'
import AnalyticsTypeInfo from '../AnalyticsTypeInfo/AnalyticsTypeInfo'
import AssetModalFooter from '../AssetModalFooter/AssetModalFooter'

type CreatePointMachinesProps = {
  turnoutId: IdType | undefined
  onCancel?: () => void
  required?:boolean
  analytics?:AnalyticsTypes
}

const CreatePointMachines: FC<PropsWithChildren<CreatePointMachinesProps>> = ({onCancel, turnoutId, required, analytics}): ReactElement => {

  const { t } = useTranslation()
  const { data: turnout, ...turnoutData } = useTurnout(turnoutId)
  const { trigger: updateAsset, ...updateAssetData } = useUpdateTurnouts()

  const handleActivate = useCallback(async () => {
    const assets = await updateAsset([{ ...turnout!, active: true }]) as Turnout[]
    if(assets && assets.length > 0) onCancel?.()
  }, [onCancel, updateAsset, turnout])

  const handleClose = useCallback(() => {
    onCancel?.()
  }, [onCancel])

  return (
    <>
      <ContextWrapper data={true} error={turnoutData.error} isLoading={turnoutData.isLoading}>
        {updateAssetData.error != null && <ErrorInfo error={updateAssetData.error} />}

        {required && turnout && <AnalyticsTypeInfo type={'message'} selectedId={turnout.analyticsTypeId} analytics={analytics} />}
        {!required && turnout && <AnalyticsTypeInfo type={'message'} selectedId={turnout.analyticsTypeId} analytics={analytics} />}

        {turnout && <>
          {turnout.pointMachines.map((pm) => (
            <div key={pm.id}>
              <UpdatePointMachineForm pointMachine={pm} turnout={turnout} key={pm.id}/>
              <hr />
            </div>
          ))}
          <UpdatePointMachineForm
            key={'create-pointmachine'}
            hideCancelButton
            turnout={turnout}
          />
        </>}
      </ContextWrapper>

      <AssetModalFooter
        required={getPointMachinesRequired(getAnalyticsTypeById(turnout?.analyticsTypeId, analytics))}
        handleNext={handleActivate}
        handleCancel={handleClose}
        loading={updateAssetData.isMutating || turnoutData.isLoading}
        nextDisabled={(turnout?.pointMachines ?? []).length === 0 || turnoutData.error}
        cancelCopy={t('assets.cta.save') ?? ""}
      />
    </>
  );
}

export default CreatePointMachines