import VegaContainer from 'components/VegaContainer'
import { TurnoutStateStatistics } from 'models'
import { JSX } from 'react'
import { useTranslation } from 'react-i18next'
import { MarkDef } from 'vega-lite/build/src/mark'

const WIDTH = 8
const MAX = 100
const GAP = WIDTH + 5

const arcs = new Array(6).fill(0).map(
  (_, index): MarkDef => ({
    innerRadius: MAX - index * GAP,
    outerRadius: MAX - WIDTH - index * GAP,
    cornerRadius: 10,
    type: 'arc',
  }),
)

export interface HealthChartProps {
  stateStatistics: TurnoutStateStatistics
}

export function HealthChart({ stateStatistics }: HealthChartProps): JSX.Element {

  const { t } = useTranslation()
  const total = stateStatistics.activationStatistics.active

  return (
    <VegaContainer
      height={200}
      spec={{
        encoding: {
          color: {
            legend: { columns: 3 },
            field: 'type',
            scale: {
              range: [
                'var(--theme-color-info)',
                'var(--theme-color-success--active)',
                'var(--theme-color-warning)',
                'var(--theme-color-alarm)',
              ],
            },
            sort: [
              t('Active'),
              t('models.turnout.state-healthy'),
              t('models.turnout.state-warning'),
              t('models.turnout.state-alert'),
            ] as any,
            title: null,
          },
          tooltip: [
            { field: 'type', title: t('Type') },
            { field: 'count', title: t('Count') },
          ],
        },
        layer: [
          {
            data: {
              values: [{ count: stateStatistics.activationStatistics.active, type: t('Active') }],
            },
            mark: arcs[0],
          },
          {
            data: {
              values: [{ count: stateStatistics.operationState.green, type: t('models.turnout.state-healthy') }],
            },
            mark: {
              ...arcs[1],
              theta: Math.PI * 2 - (Math.PI * 2 * stateStatistics.operationState.green) / total,
              theta2: Math.PI * 2,
            },
          },
          {
            data: {
              values: [{ count: stateStatistics.operationState.yellow, type: t('models.turnout.state-warning') }],
            },
            mark: {
              ...arcs[2],
              theta: Math.PI * 2 - (Math.PI * 2 * stateStatistics.operationState.yellow) / total,
              theta2: Math.PI * 2,
            },
          },
          {
            data: {
              values: [{ count: stateStatistics.operationState.red, type: t('models.turnout.state-alert') }],
            },
            mark: {
              ...arcs[3],
              theta: Math.PI * 2 - (Math.PI * 2 * stateStatistics.operationState.red) / total,
              theta2: Math.PI * 2,
            },
          },
        ],
      }}
    />
  )
}
