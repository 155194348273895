import { PointMachineEventApiClient, QueryPointMachineEventsFilterOpts } from 'api'
import { SWRResponse, useSWR } from 'lib/swr'
import { IdType, PagedPointMachineEvents, RawPointMachineEvent, ServiceError } from 'models'
import { useMemo } from 'react'
import { Key } from 'swr'
import useSWRMutation, { SWRMutationResponse } from 'swr/mutation'
import { usePointMachines } from './use-point-machine'
import { useMainProject } from './use-project'
import {
  PointMachineEventWithEstimatedWeatherDataApiClient
} from '../api/point-machine-event-with-estimated-weather-data'

export const getQueryPointMachineEventsKey = (
  opts: Parameters<typeof useQueryPointMachineEvents>[0],
  parentId?: IdType,
): Key => {
  if (parentId == null) {
    return null
  }

  return ['useQueryPointMachineEvents', parentId, JSON.stringify(opts)]
}

export const useQueryPointMachineEvents = (
  opts?: Partial<QueryPointMachineEventsFilterOpts> | null,
): SWRResponse<PagedPointMachineEvents> => {
  const { data: mainProject } = useMainProject()

  const swrResponse = useSWR(getQueryPointMachineEventsKey(opts, mainProject?.id), () => {
    return PointMachineEventWithEstimatedWeatherDataApiClient.query(mainProject!.id, opts)
  })

  const parentIds = useMemo(() => {
    return swrResponse.data?.content.map((entry) => entry.pointMachineId)
  }, [swrResponse.data])

  const parentSwrResponse = usePointMachines(null, parentIds)

  const data = useMemo<PagedPointMachineEvents | undefined>(() => {
    if (swrResponse.data == null) {
      return swrResponse.data
    }

    return {
      ...swrResponse.data,
      content: swrResponse.data.content.map((entry) => ({
        ...entry,
        pointMachine: parentSwrResponse.data?.find((parent) => parent.id === entry.pointMachineId),
      })),
    }
  }, [parentSwrResponse.data, swrResponse.data])

  return useMemo(() => {
    return {
      ...swrResponse,
      data,
      isLoading: swrResponse.isLoading || parentSwrResponse.isLoading,
      isValidating: swrResponse.isValidating || parentSwrResponse.isValidating,
    }
  }, [data, parentSwrResponse.isLoading, parentSwrResponse.isValidating, swrResponse])
}

export const useUpdatePointMachineEvents = (): SWRMutationResponse<
  RawPointMachineEvent[],
  ServiceError,
  RawPointMachineEvent[],
  Key
> => {
  return useSWRMutation('useUpdatePointMachineEvents', async (_, { arg }) => {
    const promises = arg.map(async (entry) => {
      const { data: updatedModel } = await PointMachineEventApiClient.update(entry)
      return updatedModel
    })

    const updatedModels = await Promise.all(promises)
    return updatedModels
  })
}
