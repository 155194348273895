import React, { FC, ReactElement, useMemo } from 'react'
import {
  AnalyticsTypes, AnalyticsTypeTurnOver, getAnalyticsTypeById, getPointMachinesRequired,
} from 'models'
import { Trans } from 'react-i18next'
import { IxIcon, IxMessageBar } from '@siemens/ix-react'

type AnalyticsTypeInfoProps = {
  analytics?:AnalyticsTypes
  selectedId?:number
  type?:'inline'|'message'
}

const AnalyticsTypeInfo: FC<AnalyticsTypeInfoProps> = ({analytics, selectedId, type = 'inline'}): ReactElement | null => {
  const selection = useMemo(() => {
    return getAnalyticsTypeById(selectedId, analytics)
  }, [analytics, selectedId])

  // TODO Make these info dynamic
  if(selection) {
    if(getPointMachinesRequired(selection)) {
    // AI Power Curve & Derailing Device
      if (type && type === 'message') {
        // edit form
        return <IxMessageBar type={'warning'} dismissible={false}>
          <Trans
            components={{
              br: <br />,
            }}
            i18nKey={`assets.analyticsType.info.PM_required`}
          />
        </IxMessageBar>
      }

      // create form
      return (
        <div className="AnalyticsTypeInfo mt-2 d-flex gap-1 opacity-50">
          <IxIcon name="alarm" size="24"></IxIcon>
          <span>
            <Trans
              components={{
                br: <br />,
                b: <strong />,
              }}
              i18nKey={`assets.analyticsType.info.${selection.name}`}
            />
          </span>
        </div>
      );

    } else if (type && type === 'message' && selection.name === AnalyticsTypeTurnOver) {
      return <IxMessageBar type={'info'} dismissible={false}>
        <Trans
          components={{
            br: <br />,
          }}
          i18nKey={`assets.analyticsType.info.${AnalyticsTypeTurnOver}_info`}
        />
      </IxMessageBar>
    }
  }

  return null
}

export default AnalyticsTypeInfo