import React from 'react'
import Modal, { ModalContent } from 'components/Modal'
import * as routes from 'lib/routes'
import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate } from 'react-router-dom'
import CreateAsset from '../CreateAsset/CreateAsset'
import { useAnalytics } from 'hooks'
import { IdType } from 'models'
import { ContextWrapper } from '../../../../components/ContextWrapper'

export interface CreateAssetModalProps {
  visible: boolean
  projectId:IdType
}

export function CreateAssetModal({ visible, projectId }: CreateAssetModalProps): React.JSX.Element {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { data: analytics, ...analyticsData } = useAnalytics(projectId)

  return (
    <ContextWrapper data={analytics} error={analyticsData.error} isLoading={analyticsData.isLoading}>
      <Modal title={t(`routes.${routes.createAssetConfigurationRoute.name}`)} visible={visible}>
        <ModalContent
          cancelButton={null}
          okButton={null}
          onCancel={() => navigate(generatePath(routes.assetConfigurationRoute.pathname))}
          title={t(`routes.${routes.createAssetConfigurationRoute.name}`)}
          inlineFooter
        >

            <CreateAsset
              onAfterSubmit={() => navigate(generatePath(routes.assetConfigurationRoute.pathname))}
              onCancel={() => navigate(generatePath(routes.assetConfigurationRoute.pathname))}
              analytics={analytics}
            />

        </ModalContent>
      </Modal>
    </ContextWrapper>

  )
}
