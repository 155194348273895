import Contexts from 'containers/Contexts'
import PageLayout from 'containers/PageLayout'
import * as routes from 'lib/routes'
import { AlarmsPage } from 'pages/alarms'
import { AssetConfigurationPage } from 'pages/configuration/asset'
import { ThresholdConfigurationPage } from 'pages/configuration/threshold'
import { TrackConfigurationPage } from 'pages/configuration/track'
import { UserConfigurationPage } from 'pages/configuration/user'
import { Error404Page } from 'pages/errors/404'
import { FaqPage } from 'pages/faq'
import { HomePage } from 'pages/home'
import { LogoutPage } from 'pages/logout'
import { SettingsPage } from 'pages/settings'
import { TurnoutsPage } from 'pages/turnouts'
import { TurnoutPage } from 'pages/turnouts/[turnoutId]'
import { JSX } from 'react'
import { createBrowserRouter, Outlet, RouterProvider } from 'react-router-dom'
import { GlobalStyles } from './global-styles'

const router = createBrowserRouter([
  {
    path: routes.homeRoute.pathname,
    element: (
      <PageLayout>
        <Outlet />
      </PageLayout>
    ),
    children: [
      {
        index: true,
        element: <HomePage />,
      },

      {
        path: routes.logoutRoute.pathname,
        element: <LogoutPage />,
      },

      {
        path: routes.settingsRoute.pathname,
        element: <SettingsPage />,
      },

      {
        path: routes.faqRoute.pathname,
        element: <FaqPage />,
      },

      {
        path: routes.alarmsRoute.pathname,
        element: <AlarmsPage />,
      },

      {
        path: routes.configurationRoute.pathname,
        children: [
          ...[
            routes.editTrackLayerItemConfigurationRoute,
            routes.createTrackLayerItemConfigurationRoute,
            routes.editTrackLayerConfigurationRoute,
            routes.trackConfigurationRoute,
          ].map((route) => ({
            path: route.pathname,
            element: <TrackConfigurationPage />,
          })),

          ...[
            routes.createAssetConfigurationRoute,
            routes.editAssetConfigurationRoute,
            routes.assetConfigurationRoute,
          ].map((route) => ({
            path: route.pathname,
            element: <AssetConfigurationPage />,
          })),

          ...[
            routes.alarmThresholdConfigurationRoute,
            routes.editParameterThresholdConfigurationRoute,
            routes.parameterThresholdConfigurationRoute,
            routes.referenceThresholdConfigurationRoute,
            routes.thresholdConfigurationRoute,
          ].map((route) => ({
            path: route.pathname,
            element: <ThresholdConfigurationPage />,
          })),

          ...[routes.editUserConfigurationRoute, routes.userConfigurationRoute].map((route) => ({
            path: route.pathname,
            element: <UserConfigurationPage />,
          })),
        ],
      },

      {
        path: routes.turnoutsRoute.pathname,
        children: [
          {
            index: true,
            element: <TurnoutsPage />,
          },
          {
            path: routes.turnoutRoute.pathname,
            element: <TurnoutPage />,
          },
        ],
      },

      {
        path: '*',
        element: <Error404Page />,
      },
    ],
  },
])

function Main(): JSX.Element {
  return (
    <Contexts>
      <GlobalStyles />

      <RouterProvider router={router} />
    </Contexts>
  )
}

export default Main
