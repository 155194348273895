import { IxDateDropdown } from '@siemens/ix-react'
import { JSX as IxJSX } from '@siemens/ix/dist/types/components'
import { isDateRange } from 'components/DataFilter/types'
import { DateTime } from 'luxon'
import { JSX, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FieldProps } from '../Field'
import { getDateRangeDefaults, parseDateRangeToIsoDates, getLast24HoursRange } from '../../utils'

export function DateRangeField({ field, ...props }: FieldProps): JSX.Element {
  const [dateRangeId, setDateRangeId] = useState('custom')
  const { t } = useTranslation()
  const maxDate = useMemo(() => DateTime.now().plus({ days: 1 }).startOf('day').toISODate(), [])

  const value = useMemo(() => {
    if (props.value?.value == null || Array.isArray(props.value.value)) {
      return undefined
    }
  
    const dateRange = isDateRange(props.value.value) ? props.value.value : undefined
    return {
      from: dateRange?.from.startOf('day').toISODate() ?? undefined,
      to: dateRange?.to.endOf('day').toISODate() ?? undefined
    }
  }, [props.value])

  const onChange = useCallback<NonNullable<IxJSX.IxDateDropdown['onDateRangeChange']>>(
    (event) => {
      setDateRangeId(event.detail.id)
      let value;
      if (event.detail.id === 'last-24h') {
        value = getLast24HoursRange()
      } else if (event.detail.id !== 'custom') {
        value = getDateRangeDefaults(event.detail.id)
      } else {
        value = {
          from: DateTime.fromISO(event.detail.from).startOf('day'),
          to: DateTime.fromISO(event.detail.to).endOf('day')
        }
      }
      props.onChange.call(null, field.path, value)
    },
    [field.path, props.onChange],
  )

  const dateRangeOptions = useMemo<IxJSX.IxDateDropdown['dateRangeOptions']>(() => {
    return [
      {
        id: 'last-24h',
        label: t('Last 24h'),
        ...parseDateRangeToIsoDates(getDateRangeDefaults('last-24h'))
      },
      {
        id: 'last-3d',
        label: t('Last 3d'),
        ...parseDateRangeToIsoDates(getDateRangeDefaults('last-3d'))
      },
      {
        id: 'last-7d',
        label: t('Last 7d'),
        ...parseDateRangeToIsoDates(getDateRangeDefaults('last-7d'))
      },
      {
        id: 'last-30d',
        label: t('Last 30d'),
        ...parseDateRangeToIsoDates(getDateRangeDefaults('last-30d'))
      },
    ]
  }, [t])

  useEffect(() => {
    const option = dateRangeOptions?.find((option) => option.from === value?.from && option.to === value?.to)
    if (option != null) {
      setDateRangeId(option.id)
    }
  }, [dateRangeOptions, value])

  return (
    <IxDateDropdown
      dateRangeId={dateRangeId}
      dateRangeOptions={dateRangeOptions}
      format="yyyy-LL-dd"
      from={value?.from}
      i18nCustomItem={t('Custom') ?? undefined}
      i18nDone={t('Done') ?? undefined}
      i18nNoRange={t('No range set') ?? undefined}
      maxDate={maxDate}
      onDateRangeChange={onChange}
      to={value?.to}
    />
  )
}