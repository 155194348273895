import { IxButton } from '@siemens/ix-react'
import { AgGridReact } from 'ag-grid-react'
import { PaginationFilterOpts, QueryTurnoutFilterOpts } from 'api'
import GlobalPageHeader from 'components/GlobalPageHeader'
import TurnoutConfigTable, { TurnoutConfigTableProps } from 'components/TurnoutConfigTable'
import { useFilterOpts, useMainProject, useQueryTurnouts } from 'hooks'
import { appTitle } from 'lib/config'
import * as routes from 'lib/routes'
import React, { JSX, useCallback, useMemo, useRef, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { generatePath, Link, useMatch } from 'react-router-dom'
import CreateAssetModal from './components/CreateAssetModal'
import UpdateTurnoutModal from './components/UpdateTurnoutModal'
import UpdateTurnoutsModal from './components/UpdateTurnoutsModal'
import { ContextWrapper } from '../../components/ContextWrapper'

const pageSize = 15

const route = routes.assetConfigurationRoute

export function AssetConfigurationPage(): JSX.Element {
  const [pageNo, setPageNo] = useState(0)
  const [selectedTurnoutIds, setSelectedTurnoutIds] = useState<string>()
  const [sortField, setSortField] = useState<string | undefined>('name')
  const [sortOrder, setSortOrder] = useState<PaginationFilterOpts['sortingDirection'] | undefined>('asc')
  const { t } = useTranslation()
  const createMatch = useMatch(routes.createAssetConfigurationRoute.pathname)
  const editMatch = useMatch(routes.editAssetConfigurationRoute.pathname)
  const ref = useRef<AgGridReact | null>(null)

  const filterOpts = useFilterOpts<QueryTurnoutFilterOpts>([], {
    page: pageNo,
    size: pageSize,
    sortBy: sortField,
    sortingDirection: sortOrder,
  })

  const { data: mainProject, ...mainProjectData } = useMainProject()
  const { data: queryResponse, ...queryResponseData } = useQueryTurnouts(filterOpts)

  const turnoutIds = useMemo(() => {
    const turnoutIds = editMatch?.params.assetId
      ?.split(',')
      .map((d) => Number(d))
      .filter((d) => !Number.isNaN(d))
    return turnoutIds == null || turnoutIds.length < 2 ? undefined : turnoutIds
  }, [editMatch?.params.assetId])

  const turnoutId = useMemo(() => {
    const turnoutIds = editMatch?.params.assetId
      ?.split(',')
      .map((d) => Number(d))
      .filter((d) => !Number.isNaN(d))
    return turnoutIds?.length === 1 ? turnoutIds?.at(0) : undefined
  }, [editMatch?.params.assetId])

  const onSelectionChanged = useCallback<NonNullable<TurnoutConfigTableProps['onSelectionChanged']>>(({ api }) => {
    const turnouts = api.getSelectedRows()
    setSelectedTurnoutIds(turnouts.length === 0 ? undefined : turnouts.map((turnout) => turnout.id).join(','))
  }, [])

  const onSort = useCallback<NonNullable<TurnoutConfigTableProps['onSortChanged']>>(({ columnApi }) => {
    const columns = columnApi.getColumnState()
    const sortedColumn = columns.find((column) => column.sort != null)

    setSortField(sortedColumn?.colId)
    setSortOrder(sortedColumn?.sort ?? undefined)
  }, [])

  return (
    <>
      <Helmet>
        <title>
          {appTitle} - {t(`routes.${route.name}`)}
        </title>
      </Helmet>

      <GlobalPageHeader route={route} title={t(`routes.${route.name}`)}>
        <ContextWrapper data={mainProjectData} error={mainProjectData.error} isLoading={mainProjectData.isLoading}>
          <div className="d-flex justify-content-end mb-4">
            <div>
              <Link
                className="me-2"
                to={
                  selectedTurnoutIds == null
                    ? {}
                    : generatePath(routes.editAssetConfigurationRoute.pathname, { assetId: selectedTurnoutIds })
                }
              >
                <IxButton disabled={selectedTurnoutIds == null} outline>
                  {t('Bulk edit')}
                </IxButton>
              </Link>

              <Link to={generatePath(routes.createAssetConfigurationRoute.pathname)}>
                <IxButton>{t(`routes.${routes.createAssetConfigurationRoute.name}`)}</IxButton>
              </Link>
            </div>
          </div>

          <TurnoutConfigTable
            data={queryResponse?.content}
            error={queryResponseData.error}
            gridRef={ref}
            isLoading={queryResponseData.isLoading}
            onSelectionChanged={onSelectionChanged}
            onSortChanged={onSort}
            paginationPageSize={pageSize}
            paginationProps={{
              count: queryResponse?.totalPages,
              onPageSelected: (event) => setPageNo(event.detail),
              selectedPage: queryResponse?.number,
            }}
          />

          {mainProject &&
            <>
              <CreateAssetModal visible={Boolean(createMatch)} projectId={mainProject!.id}/>

              <UpdateTurnoutModal turnoutId={turnoutId} visible={turnoutId != null} projectId={mainProject!.id} />
              <UpdateTurnoutsModal turnoutIds={turnoutIds}  visible={turnoutIds != null} projectId={mainProject!.id} />
            </>
          }
        </ContextWrapper>
      </GlobalPageHeader>
    </>
  )
}
