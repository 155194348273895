import {
  IxButton,
  IxDropdown,
  IxDropdownHeader,
  IxDropdownQuickActions,
  IxIcon,
  IxIconButton,
  IxPill,
  IxSelect,
  IxSelectItem,
  IxToggle,
  IxValidationTooltip,
} from '@siemens/ix-react'
import ContextWrapper from 'components/ContextWrapper'
import ErrorInfo from 'components/ErrorInfo'
import { useCreateAsset, useDeleteTurnouts, useTechnicalRooms, useUpdateTurnouts } from 'hooks'
import { DateTime } from 'luxon'
import {
  AnalyticsTypes,
  getAnalyticsTypeById, getAnalyticsTypeEnabled,
  getPointMachinesRequired,
  Turnout,
  TurnoutFunctionalityType, TurnoutFunctionalityTypeDerail,
} from 'models'
import React, { JSX, PropsWithChildren, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import { getDefaultFormValues, UpdateTurnout } from './lib'
import AnalyticsTypeInfo from '../../pages/configuration/components/AnalyticsTypeInfo/AnalyticsTypeInfo'
import AssetModalFooter from '../../pages/configuration/components/AssetModalFooter/AssetModalFooter'
import InfoPopover from '../Popover/InfoPopover'

const local = DateTime.local()
const aryIannaTimeZones = (Intl as any).supportedValuesOf('timeZone') as string[]
const zones = aryIannaTimeZones.map((timeZone) => {
  return { timeZone, offset: local.setZone(timeZone).toFormat('Z') }
})
zones.sort((a, b) => {
  const aNum = Number.parseFloat(a.offset)
  const bNum = Number.parseFloat(b.offset)
  return aNum - bNum
})

export interface UpdateTurnoutFormProps {
  onAfterDelete?: () => void
  onAfterSubmit?: (assets?: Turnout[]) => void
  onUpdate?: (asset: Turnout) => void
  onCancel?: () => void
  /**
   * Calling onBeforeDelete prevents the asset from being updated/reloaded
   */
  onBeforeDelete?: () => void
  onRemoveItem?: (item: Turnout, remaining?: Turnout[]) => void
  turnout?: Turnout | Turnout[]
  assetType?: TurnoutFunctionalityType
  analytics?: AnalyticsTypes
  /**
   * used to block saving (if e.g. no required point machines connected)
   */
  savingDisabled?: boolean
}

export function UpdateTurnoutForm({
                                    onAfterSubmit,
                                    onCancel,
                                    onBeforeDelete,
                                    onUpdate,
                                    assetType,
                                    analytics,
                                    ...props
                                  }: PropsWithChildren<UpdateTurnoutFormProps>): JSX.Element {
  const [turnout, setTurnout] = useState(props.turnout)
  const defaultValues = useMemo(() => {
    const values = getDefaultFormValues(turnout)
    if (!props.turnout && assetType) {
      values.turnoutFunctionalityType = assetType

      if(assetType === TurnoutFunctionalityTypeDerail) {
        values.analyticsTypeId = analytics?.find((at) => at.name === 'turntime_expertsystem')?.id
      }
    }

    return values
  }, [turnout, assetType,analytics, props.turnout])

  const { data: technicalRooms, ...technicalRoomsData } = useTechnicalRooms()
  const { control, register, handleSubmit, formState, reset, watch, getValues } = useForm<UpdateTurnout>({
    values: defaultValues,
  })

  const popoverRoot = useRef(null)

  // Form Watcher
  const analyticsTypeId: number | null | undefined = watch('analyticsTypeId')
  const isActive = watch('active')

  const { t } = useTranslation()
  const { trigger: createAsset, ...createAssetData } = useCreateAsset()
  const { trigger: updateAsset, ...updateAssetData } = useUpdateTurnouts()
  const { trigger: deleteAssets, ...deleteAssetsData } = useDeleteTurnouts()
  const formRef = useRef<HTMLFormElement | null>(null)

  /**
   * Disables the next button if PMs are required but not set
   */
  const pmsRequired = useMemo(() => {
    const currentAnalyticsType = getAnalyticsTypeById(analyticsTypeId, analytics)

    if (
      props.turnout && !Array.isArray(props.turnout)
      && currentAnalyticsType
      && props.turnout.pointMachines.length === 0
    ) {
      // Only used on edit-asset-mode
      return getPointMachinesRequired(currentAnalyticsType) && isActive // User is able to save devices w/o point machines if the device is inactive
    }

    return false
  }, [props.turnout, analytics, analyticsTypeId, isActive])


  useEffect(() => {
    // Initial reset
    reset()
  }, [reset])

  const onFinish = useCallback<SubmitHandler<UpdateTurnout>>(
    async (formValues) => {
      let assets: Turnout[]
      if (turnout == null) {
        assets = [await createAsset(formValues) as Turnout]
      } else if (Array.isArray(turnout)) {
        assets = await updateAsset(turnout.map((turnout) => ({ ...turnout, ...formValues }))) as Turnout[]
      } else {
        assets = await updateAsset([{ ...turnout, ...formValues }]) as Turnout[]
      }

      onAfterSubmit?.(assets)
    },
    [createAsset, onAfterSubmit, turnout, updateAsset],
  )

  const onDelete = useCallback(async () => {
    if (turnout == null) {
      return
    }

    onBeforeDelete?.()

    if (Array.isArray(turnout)) {
      await deleteAssets(turnout.map((turnout) => turnout.id))
    } else {
      await deleteAssets([turnout.id])
    }

    onAfterSubmit?.()
  }, [deleteAssets, onAfterSubmit, turnout, onBeforeDelete])

  const onRemoveItem = useCallback(
    (item: Turnout) => {
      setTurnout((prev) => {
        if (Array.isArray(prev)) {
          const newTurnouts = prev.filter((turnout) => turnout.id !== item.id)
          props.onRemoveItem?.(item, newTurnouts)
          return newTurnouts
        }

        props.onRemoveItem?.(item)
        return prev
      })
    },
    [props],
  )

  const onClickNext = useCallback(() => {
    if (props.turnout) {
      if (!formState.isDirty && onCancel) {
        reset()
        onCancel()
        return
      }
    }
    handleSubmit(onFinish)()
  }, [handleSubmit, onFinish, onCancel, props.turnout, reset, formState.isDirty])

  const onClickCancel = useCallback(() => {
    if (onCancel) {
      return onCancel()
    }
    reset()
  }, [onCancel, reset])

  const handleUpdate = useCallback(() => {
    if (onUpdate) onUpdate(getValues() as Turnout)
  }, [onUpdate, getValues])

  const nextCopy: string = useMemo(() => {
    if (Boolean(turnout) && formState.isDirty) {
      return t('Save turnout', { count: Array.isArray(turnout) ? turnout.length : 1 })
    } else if (!Boolean(turnout)) {
      return t('Next')
    }
    return t('Done')
  }, [t, turnout, formState.isDirty])

  const pointMachinesConnected = useMemo<boolean>(() => {
    if(Array.isArray(turnout)) {
      return Boolean(turnout.find((t) => t.pointMachines.length > 0))
    }
    return (turnout?.pointMachines ?? []).length > 0
  }, [turnout])

  return (
    <ContextWrapper data={true} error={technicalRoomsData.error} isLoading={technicalRoomsData.isLoading}>
      <form className="row gx-5 gy-3" onSubmit={handleSubmit(onFinish)} ref={formRef} onChange={handleUpdate}>
        {createAssetData.error != null && <ErrorInfo error={createAssetData.error} />}
        {updateAssetData.error != null && <ErrorInfo error={updateAssetData.error} />}
        {deleteAssetsData.error != null && <ErrorInfo error={deleteAssetsData.error} />}

        {Array.isArray(turnout) && (
          <div>
            {turnout.map((turnout) => (
              <IxPill key={turnout.id}>
                {turnout.name}{' '}
                <IxIcon
                  name="close"
                  onClick={onRemoveItem.bind(null, turnout)}
                  size="12"
                  style={{ cursor: 'pointer' }}
                />
              </IxPill>
            ))}
          </div>
        )}

        {!Array.isArray(turnout) && (
          <input
            className={`form-control ${formState.errors.turnoutFunctionalityType ? 'is-invalid' : ''}`}
            id="turnoutFunctionalityType"
            type="text"
            hidden
            autoComplete="off"
            {...register('turnoutFunctionalityType', { required: true })}
          />
        )}

        {!Array.isArray(turnout) && (
          <div className="col-12 col-lg-6">
            <IxValidationTooltip className="w-100" message={t('Required field') ?? undefined}>
              <label htmlFor="name" className="form-label">
                {t('Name')} *
              </label>

              <input
                className={`form-control ${formState.errors.name ? 'is-invalid' : ''}`}
                id="name"
                type="text"
                {...register('name', { required: true })}
              />
            </IxValidationTooltip>
          </div>
        )}

        {!Array.isArray(turnout) && (
          <div className="col-12 col-lg-6">
            <IxValidationTooltip className="w-100" message={t('Required field') ?? undefined}>
              <label htmlFor="engineeringId" className="form-label">
                ID *
              </label>

              <input
                className={`form-control ${formState.errors.engineeringId ? 'is-invalid' : ''}`}
                id="engineeringId"
                type="text"
                {...register('engineeringId', { required: true })}
              />
            </IxValidationTooltip>
          </div>
        )}

        <div className="col-12 col-lg-6">
          <div>
            <label htmlFor="timezone" className="form-label">
              {t('Timezone')} *
            </label>

            <Controller
              control={control}
              name="timezone"
              rules={{ required: true }}
              render={({ field: { name, onChange, onBlur, ref, value } }) => (
                <IxSelect
                  className={`w-100 ${formState.errors.timezone ? 'is-invalid' : ''}`}
                  hideListHeader
                  i18nPlaceholder=""
                  id={name}
                  onBlur={onBlur}
                  onValueChange={(data) => onChange(data.detail)}
                  ref={ref}
                  value={value}
                >
                  {zones.map((zone) => (
                    <IxSelectItem
                      key={zone.timeZone}
                      label={`${zone.timeZone} GMT${zone.offset}`}
                      value={zone.timeZone}
                    />
                  ))}
                </IxSelect>
              )}
            />
          </div>
        </div>

        {!Array.isArray(turnout) && (
          <div className="col-12 col-lg-6">
            <div className="w-100">
              <label htmlFor="trackPoint" className="form-label">
                {t('models.turnout.trackPoint')}
              </label>

              <input
                className={`form-control ${formState.errors.trackPoint ? 'is-invalid' : ''}`}
                max={255}
                id="trackPoint"
                type="text"
                {...register('trackPoint', { required: false })}
              />
            </div>
          </div>
        )}

        <div className="col-12"></div>

        {!Array.isArray(turnout) && (
          <div className="col-12 col-lg-6">
            <div>
              <label htmlFor="technicalRoom" className="form-label">
                {t('Technical room')} *
              </label>

              <Controller
                control={control}
                name="technicalRoom"
                rules={{ required: true }}
                render={({ field: { name, onChange, onBlur, ref, value } }) => (
                  <IxSelect
                    className={`w-100 ${formState.errors.technicalRoom ? 'is-invalid' : ''}`}
                    hideListHeader
                    i18nPlaceholder=""
                    id={name}
                    onBlur={onBlur}
                    onValueChange={(data) =>
                      onChange({ id: Number(Array.isArray(data.detail) ? data.detail[0] : data.detail) })
                    }
                    ref={ref}
                    value={value?.id == null ? [] : [String(value.id)]}
                  >
                    {technicalRooms?.map((entry) => (
                      <IxSelectItem key={entry.id} label={entry.name} value={String(entry.id)} />
                    ))}
                  </IxSelect>
                )}
              />
            </div>
          </div>
        )}

        {!Array.isArray(turnout) && (
          <div className="col-12 col-lg-6">
            <IxValidationTooltip className="w-100" message={t('Required field') ?? undefined}>
              <label htmlFor="waypoint" className="form-label">
                {t('models.turnout.waypoint')} *
              </label>

              <input
                className={`form-control ${formState.errors.waypoint ? 'is-invalid' : ''}`}
                id="waypoint"
                type="text"
                {...register('waypoint' as any, { required: true })}
              />
            </IxValidationTooltip>
          </div>
        )}

        {!Array.isArray(turnout) && (
          <div className="col-12 flex-column">
            <label className="form-label">
              {t('models.turnout.model-type')} *
              <InfoPopover
                copy={
                <Trans
                    components={{
                      br: <br />,
                      /* eslint-disable-next-line */
                      a: <a />
                    }}
                    values={{url: '/faq', target: '_blank'}}
                    i18nKey={'assets.create.description.analyticsType'}
                  />
                }
                root={popoverRoot}
              />
            </label>
            <Controller
              rules={{ required: true }}
              control={control}
              name="analyticsTypeId"
              render={({ field }) => (
                <div className={`d-flex flex-row ${formState.errors.analyticsTypeId ? 'is-invalid' : ''}`}>
                  {analytics?.map((aType) => {
                    const disabled = !getAnalyticsTypeEnabled(aType, turnout?.turnoutFunctionalityType ?? assetType)
                    return [
                      <input
                        id={aType.name}
                        key={`${aType.name}-input`}
                        defaultChecked={aType.id === turnout?.analyticsTypeId || aType.id === defaultValues.analyticsTypeId}
                        disabled={disabled}
                        type="radio"
                        {...field}
                        onChange={() => field.onChange(aType.id)} // Store number instead of string
                        value={aType.id}
                      />,
                      <label
                        key={`${aType.name}-label`}
                        htmlFor={aType.name}
                      >
                        {t(`models.turnout.analyticsTypes.${aType.name}`)}
                      </label>,
                    ]
                  })}
                </div>
              )}
            />
            {assetType && <AnalyticsTypeInfo selectedId={analyticsTypeId as number} analytics={analytics} />}
          </div>
        )}

        {!Array.isArray(turnout) && turnout && (
          <div className="col-12">
            <IxValidationTooltip className="w-100" message={t('Required field') ?? undefined}>
              <div className={'d-flex flex-column'}>
                <label className="form-label" style={{ marginBottom: 0 }}>
                  {t('models.turnout.activity-status')}
                  <InfoPopover
                    copy={
                      <Trans
                        components={{
                          br: <br />,
                        }}
                        i18nKey={`assets.create.description.status`}
                      />
                    }
                    root={popoverRoot}
                  />
                </label>
                <label htmlFor="active-device" className="form-label">
                  <IxToggle
                    className={`${formState.errors.active ? 'is-invalid' : ''}`}
                    textOff={'This device is inactive'}
                    textOn={'This device is active'}
                    checked={isActive}
                    style={{ pointerEvents: 'none' }}
                  />
                </label>
                <input
                  type="checkbox"
                  id="active-device"
                  defaultChecked={false}
                  hidden
                  {...register('active')}
                />
              </div>
            </IxValidationTooltip>
          </div>
        )}

        <div><small>(*) - {t('Required field')}</small></div>

        {props.children}

        <AssetModalFooter
          required={pmsRequired}
          handleNext={onClickNext}
          handleCancel={onClickCancel}
          loading={updateAssetData.isMutating}
          nextDisabled={true}
          nextCopy={nextCopy}
        >
          {!Array.isArray(turnout) && turnout && <>
            <IxButton
              id="UpdateTurnoutForm_Delete_turnout"
              loading={deleteAssetsData.isMutating}
              outline
              variant="secondary"
            >
              {t('Delete turnout', { count: Array.isArray(turnout) ? turnout.length : 1 })}
            </IxButton>

            <IxDropdown placement="top-start" trigger="UpdateTurnoutForm_Delete_turnout">
              <IxDropdownHeader label={t('Are you sure?') ?? ''} className={'h-auto'} />
              {pointMachinesConnected && <div className={'d-flex flex-row ms-3 mb-2 gap-0 align-items-center'}>
                <IxIcon name={'warning'} size={"24"} />
                <IxDropdownHeader label={t('Delete pointmachines') ?? ''} className={'h-auto ps-1'} />
              </div>}
              <IxDropdownQuickActions className={'justify-content-between'}>
                <IxIconButton icon="cancel" ghost />
                <IxIconButton icon="single-check" ghost onClick={onDelete} />
              </IxDropdownQuickActions>
            </IxDropdown>

          </>}
        </AssetModalFooter>
        <div id={"popover-root"} ref={popoverRoot} style={{position: 'fixed'}}></div>
      </form>
    </ContextWrapper>
  )
}
