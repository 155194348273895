import { IxKeyValue, IxKeyValueList, IxTabItem, IxTabs, IxTile } from '@siemens/ix-react'
import ContextWrapper from 'components/ContextWrapper'
import { usePointMachineChangeLogs, usePointMachines } from 'hooks'
import { DateTime } from 'luxon'
import { IdType } from 'models'
import { JSX, useState } from 'react'
import { useTranslation } from 'react-i18next'

export interface PointMachineInfoTileProps {
  turnoutId: IdType
}

export function PointMachineInfoTile({ turnoutId }: PointMachineInfoTileProps): JSX.Element {
  const [tab, setTab] = useState(0)
  const { data: pointMachines, ...pointMachinesData } = usePointMachines(turnoutId)
  const { data: changeLogs, ...changeLogsData } = usePointMachineChangeLogs(turnoutId)
  const { i18n, t } = useTranslation()

  const pm = pointMachines?.[tab]
  const changeLog = changeLogs?.at(0)

  return (
    <IxTile>
      <div slot="header">{t('Point machine info')}</div>

      <div>
        <ContextWrapper data={pointMachines} error={pointMachinesData.error} isLoading={pointMachinesData.isLoading}>
          {(pointMachines) => (
            <ContextWrapper data={true} error={changeLogsData.error} isLoading={changeLogsData.isLoading}>
              <>
                <IxTabs selected={tab}>
                  {pointMachines.sort((a, b) => (a.position - b.position)).map((pm, index) => (
                    <IxTabItem key={pm.id} onClick={setTab.bind(null, index)}>
                      {pm.name}
                    </IxTabItem>
                  ))}

                  <IxTabItem onClick={setTab.bind(null, pointMachines?.length ?? 0)}>{t('Changelog')}</IxTabItem>
                </IxTabs>

                {pm == null ? (
                  <IxKeyValueList>
                    <IxKeyValue
                      label="PM ID"
                      labelPosition="left"
                      value={pointMachines.find((pm) => pm.id === changeLog?.pointMachineId)?.engineeringId ?? ' – '}
                    />
                    <IxKeyValue label="ID" labelPosition="left" value=" – " />
                    <IxKeyValue label={t('Installation date')} labelPosition="left" value=" – " />
                    <IxKeyValue
                      label={t('Serial number')}
                      labelPosition="left"
                      value={changeLog?.newSerialNumber ?? ' – '}
                    />
                    <IxKeyValue
                      label={t('Deleted at')}
                      labelPosition="left"
                      value={
                        changeLog == null
                          ? ' – '
                          : DateTime.fromISO(changeLog.changeAt)
                              .setLocale(i18n.language)
                              .toLocaleString(DateTime.DATETIME_MED)
                      }
                    />
                    <IxKeyValue
                      label={t('Deleted by')}
                      labelPosition="left"
                      value={changeLog?.nameOfTheUser ?? ' – '}
                    />
                  </IxKeyValueList>
                ) : (
                  <IxKeyValueList>
                    <IxKeyValue label="PM ID" labelPosition="left" value={pm.engineeringId} />
                    <IxKeyValue
                      label={t('models.event.direction')}
                      labelPosition="left"
                      value={t(`models.event.direction-${pm.fixedDirection.toLowerCase()}`) ?? undefined}
                    />
                    <IxKeyValue label={t('Installation date')} labelPosition="left" value=" – " />
                    <IxKeyValue label={t('Position')} labelPosition="left" value={String(pm.position)} />
                    <IxKeyValue label={t('Serial number')} labelPosition="left" value={pm.serialNumber} />
                  </IxKeyValueList>
                )}
              </>
            </ContextWrapper>
          )}
        </ContextWrapper>
      </div>
    </IxTile>
  )
}
