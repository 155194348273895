import { TurnoutHealthStatesFilterOpts } from 'api'
import ConcatenatedIncidentGraph, { DataPoint, useEncoding } from 'components/ConcatenatedIncidentChart'
import ContextWrapper from 'components/ContextWrapper'
import { useProjectConfiguration, useTurnoutDisconnectPeriods, useTurnoutHealthStates } from 'hooks'
import { conditions } from 'lib/conditions'
import { DateTime } from 'luxon'
import { Turnout } from 'models'
import { JSX, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Encoding } from 'vega-lite/build/src/encoding'
import { AnyMark } from 'vega-lite/build/src/mark'
import { GenericUnitSpec } from 'vega-lite/build/src/spec'

export interface HealthStateChartProps {
  filterOpts: Partial<TurnoutHealthStatesFilterOpts> | undefined
  turnout: Turnout
}

export function HealthStateChart({ filterOpts, turnout }: HealthStateChartProps): JSX.Element {
  const disconnectPeriodsFilterOpts = useMemo<Partial<TurnoutHealthStatesFilterOpts> | undefined>(() => {
    return { ...filterOpts, turnoutNameFilter: turnout.engineeringId }
  }, [filterOpts, turnout.engineeringId])

  const [baseEncoding] = useEncoding(filterOpts)
  const { data: healthStates, ...healthStatesData } = useTurnoutHealthStates(turnout.id, filterOpts)
  const { data: disconnectPeriods, ...disconnectPeriodsData } = useTurnoutDisconnectPeriods(
    turnout.id,
    disconnectPeriodsFilterOpts,
  )
  const { data: projectConfiguration, ...projectConfigurationData } = useProjectConfiguration()
  const { t } = useTranslation()

  const data = useMemo<DataPoint[]>(() => {
    if (healthStates == null) {
      return []
    }

    return healthStates.map(
      (item): DataPoint => ({
        start: item.timestamp,
        type: item.score == null ? undefined : String(item.score),
        value: item.healthState == null ? undefined : item.healthState,
      }),
    )
  }, [healthStates])

  const segmentLayer = useMemo((): GenericUnitSpec<Encoding<string>, AnyMark> => {
    const values =
      projectConfiguration == null
        ? []
        : [
            {
              type: t('models.turnout.state-alert'),
              y1: 0,
              y2: projectConfiguration.thresholdFailure,
            },
            {
              type: t('models.turnout.state-warning'),
              y1: projectConfiguration.thresholdFailure,
              y2: projectConfiguration.thresholdWarning,
            },
            {
              type: t('models.turnout.state-healthy'),
              y1: projectConfiguration.thresholdWarning,
              y2: 1,
            },
          ]
    const sort = [t('models.turnout.state-alert'), t('models.turnout.state-warning'), t('models.turnout.state-healthy')]

    return {
      data: { values },
      mark: { type: 'rect' },
      encoding: {
        color: {
          field: 'type',
          scale: { range: [conditions.interference.color, conditions.warning.color, conditions.healthy.color] },
          sort,
          title: t('models.healthState.healthState'),
        },
        opacity: {
          value: 1 / 3,
        },
        y: {
          field: 'y1',
          scale: { domain: [1, 0] },
          title: null,
          type: 'quantitative',
        },
        y2: {
          field: 'y2',
          title: null,
          type: 'quantitative',
        },
      },
    }
  }, [projectConfiguration, t])

  const connectionRectLayer = useMemo((): GenericUnitSpec<Encoding<string>, AnyMark> => {
    const values = (disconnectPeriods ?? []).map((item) => ({
      x1: DateTime.fromISO(item.from),
      x2: DateTime.fromISO(item.to),
      y: 0,
      y2: 1,
    }))

    return {
      data: { values },
      mark: {
        color: 'var(--theme-color-6)',
        type: 'rect',
      },
      encoding: {
        opacity: { value: 0.9 },
        x: {
          field: 'x1',
          title: null,
          type: 'temporal',
        },
        x2: {
          field: 'x2',
          title: null,
          type: 'temporal',
        },
        y: {
          field: 'y',
          scale: { domain: [1, 0] },
          title: null,
          type: 'quantitative',
        },
        y2: {
          field: 'y2',
          title: null,
          type: 'quantitative',
        },
      },
    }
  }, [disconnectPeriods])

  const connectionImageLayer = useMemo((): GenericUnitSpec<Encoding<string>, AnyMark> => {
    const values = (disconnectPeriods ?? []).map((item) => {
      const to = DateTime.fromISO(item.to)
      const diff = to.diff(DateTime.fromISO(item.from), 'milliseconds')
      const middle = to.minus({ milliseconds: diff.milliseconds / 2 })

      return {
        x: middle,
        y: 0.4,
      }
    })

    return {
      data: { values },
      mark: {
        color: 'var(--theme-color-contrast-bdr)',
        shape:
          'M -0.69 0.8361 L -0.5121 1.014 L -0.3939 0.8958 L -0.2516 1.0381 L -0.3698 1.1563 L -0.1563 1.3698 L -0.0381 1.2516 L 0.1042 1.3939 L -0.014 1.5121 L 0.1639 1.69 L 0.0216 1.8323 L -0.0496 1.7612 C -0.2217 1.9333 -0.4875 1.9546 -0.6829 1.8252 L -0.8539 1.9962 L -0.9962 1.8539 L -0.8252 1.6829 C -0.9546 1.4875 -0.9333 1.2217 -0.7612 1.0496 L -0.8323 0.9784 L -0.69 0.8361 Z M -0.7842 0.0735 L 0.9265 1.7842 L 0.7842 1.9265 L -0.9265 0.2158 L -0.7842 0.0735 Z M -0.6188 1.1919 C -0.7367 1.3098 -0.7367 1.5009 -0.6188 1.6188 C -0.5052 1.7325 -0.3234 1.7366 -0.2048 1.631 L -0.1919 1.6188 L -0.6188 1.1919 Z M 0.8539 0.0038 L 0.9962 0.1461 L 0.7749 0.3674 C 0.9043 0.5628 0.883 0.8286 0.7108 1.0007 L 0.782 1.0719 L 0.6397 1.2142 L -0.2142 0.3603 L -0.0719 0.218 L -0.0007 0.2892 C 0.1714 0.117 0.4372 0.0957 0.6326 0.2251 L 0.8539 0.0038 Z M 0.1545 0.4193 L 0.1416 0.4315 L 0.5632 0.8531 C 0.6468 0.7261 0.67 0.533 0.5685 0.4315 C 0.4548 0.3178 0.273 0.3137 0.1545 0.4193 Z',
        size: 1000,
        type: 'point',
      },
      encoding: {
        x: {
          field: 'x',
          title: null,
          type: 'temporal',
        },
        y: {
          field: 'y',
          scale: { domain: [1, 0] },
          title: null,
          type: 'quantitative',
        },
      },
    }
  }, [disconnectPeriods])

  const lineLayer = useMemo((): GenericUnitSpec<Encoding<string>, AnyMark> => {
    return {
      data: { values: data },
      mark: { type: 'line' },
      encoding: {
        ...baseEncoding,
        opacity: { condition: { param: 'highlightName', value: 1 }, value: 0.2 },
        color: { value: 'var(--theme-color-std-text)' },
        tooltip: [
          { field: 'start', format: '%c', title: t('Timestamp'), type: 'temporal' },
          { field: 'type', format: '.2', title: t('models.healthState.score') },
          { field: 'value', format: '.2', title: t('models.healthState.healthState') },
        ],
        y: {
          field: 'value',
          scale: { domain: [1, 0], bins: [1, 0.75, 0.5, 0.25, 0] },
          title: null,
          type: 'quantitative',
        },
      },
      params: [
        // highlight
        {
          bind: 'legend',
          name: 'highlightName',
          select: { type: 'point', fields: ['type'] },
        },

        // zoom
        // https://github.com/vega/vega-lite/pull/4948 😭
        // {
        //   bind: {},
        //   name: 'grid',
        //   select: { type: 'interval', encodings: ['x'] },
        // },
      ],
    }
  }, [baseEncoding, data, t])

  const layers = useMemo(() => {
    return [segmentLayer, lineLayer, connectionRectLayer, connectionImageLayer]
  }, [connectionImageLayer, connectionRectLayer, lineLayer, segmentLayer])

  return (
    <ContextWrapper
      data={true}
      error={healthStatesData.error ?? projectConfigurationData.error ?? disconnectPeriodsData.error}
      isLoading={healthStatesData.isLoading || projectConfigurationData.isLoading || disconnectPeriodsData.isLoading}
    >
      <ConcatenatedIncidentGraph height={200} layers={layers} filterOpts={{ ...filterOpts, turnoutName: turnout.name }} />
    </ContextWrapper>
  )
}
