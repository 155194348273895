import { IxTile } from '@siemens/ix-react'
import { AgGridReact } from 'ag-grid-react'
import { PaginationFilterOpts, QueryTurnoutFilterOpts } from 'api'
import DataFilter, { Filter } from 'components/DataFilter'
import TurnoutTable, { TurnoutTableProps } from 'components/TurnoutTable'
import { usePageHeader } from 'contexts/PageHeader'
import { useExportData, useFilterOpts, useQueryTurnouts, useTurnoutSearchFields } from 'hooks'
import { JSX, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import TurnoutsKpiBar from '../TurnoutsKpiBar'

const pageSize = 15

export function TurnoutListing(): JSX.Element {
  const [filters, setFilters] = useState<Filter[]>([])
  const [pageNo, setPageNo] = useState(0)
  const [sortField, setSortField] = useState<string | undefined>('operationState')
  const [sortOrder, setSortOrder] = useState<PaginationFilterOpts['sortingDirection'] | undefined>('desc')
  const { setLastUpdate } = usePageHeader()
  const { t } = useTranslation()

  const ref = useRef<AgGridReact | null>(null)
  const { onClickExport } = useExportData(ref)

  const filterOpts = useFilterOpts<Partial<QueryTurnoutFilterOpts>>(filters)
  const pagedFilterOpts = useMemo<Partial<QueryTurnoutFilterOpts>>(() => {
    return {
      ...filterOpts,
      page: pageNo,
      size: pageSize,
      sortBy: sortField,
      sortingDirection: sortOrder,
      active: true,
    }
  }, [filterOpts, pageNo, sortField, sortOrder])

  const { data: pagedTurnouts, ...pagedTurnoutsData } = useQueryTurnouts(pagedFilterOpts)

  useEffect(() => {
    setLastUpdate(pagedTurnoutsData.dateTime)
  }, [setLastUpdate, pagedTurnoutsData.dateTime])

  const onSort = useCallback<NonNullable<TurnoutTableProps['onSortChanged']>>(({ columnApi }) => {
    const columns = columnApi.getColumnState()
    const sortedColumn = columns.find((column) => column.sort != null)

    setSortField(sortedColumn?.colId)
    setSortOrder(sortedColumn?.sort ?? undefined)
  }, [])

  return (
    <div className="row g-3">
      <div className="col-12">
        <DataFilter
          fields={[
            {
              icon: 'search',
              label: t('Search for turnout name'),
              path: 'turnoutName',
              type: 'text',
            },
            ...useTurnoutSearchFields(filters, true),
          ]}
          onChange={setFilters}
          onExport={onClickExport}
          value={filters}
        />
      </div>

      {filterOpts != null && <TurnoutsKpiBar filterOpts={filterOpts} />}

      <div className="col-12">
        <IxTile>
          <TurnoutTable
            data={pagedTurnouts?.content}
            error={pagedTurnoutsData.error}
            gridRef={ref}
            isLoading={pagedTurnoutsData.isLoading}
            onSortChanged={onSort}
            paginationPageSize={pageSize}
            paginationProps={{
              count: pagedTurnouts?.totalPages,
              onPageSelected: (event) => setPageNo(event.detail),
              selectedPage: pagedTurnouts?.number,
            }}
            selectable
          />
        </IxTile>
      </div>
    </div>
  )
}
