import {
  DateRangeFilterOpts,
  QueryTurnoutFilterOpts,
  TurnoutApiClient,
  TurnoutHealthStatesFilterOpts,
  TurnoutStateStatisticsFilterOpts,
} from 'api'
import { Filter, SearchField } from 'components/DataFilter'
import { useMainProject, useTechnicalRoom, useTechnicalRooms } from 'hooks'
import { SWRResponse, useSWR } from 'lib/swr'
import { DateTime } from 'luxon'
import {
  BaseModel,
  BaseTurnoutWithPms,
  DisconnectPeriod,
  HealthState,
  IdType,
  PagedTurnouts,
  RawTurnout,
  ServiceError,
  Turnout,
  TurnoutStateStatistics,
  TurnoutStatistics,
} from 'models'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Key, useSWRConfig } from 'swr'
import useSWRMutation from 'swr/mutation'
import { SWRMutationResponse } from 'swr/mutation/dist/mutation'
import { usePointMachineSearchFields } from './use-point-machine'

export const getTurnoutsKey = (parentId: Parameters<typeof useTurnouts>[0], parents?: BaseModel[]): Key => {
  if (parentId == null) {
    if (parents == null) {
      return null
    }

    return ['useAssets', ...parents.map((parent) => parent.id)]
  }

  return ['useAssets', parentId]
}

export const useTurnouts = (parentId?: IdType | null, turnoutIds?: IdType[] | null): SWRResponse<Turnout[]> => {
  const { data: parents, ...parentsData } = useTechnicalRooms()

  const swrResponse = useSWR(getTurnoutsKey(parentId, parents), async () => {
    if (parentId == null) {
      const responses = await Promise.all(parents!.map((parent) => TurnoutApiClient.list(parent.id)))
      const allEntries = responses.flatMap((entry) => entry.data)
      return { data: allEntries, dateTime: responses[0].dateTime }
    }

    return TurnoutApiClient.list(parentId)
  })

  const data = useMemo<Turnout[] | undefined>(() => {
    const ids = turnoutIds?.map(String)

    return swrResponse.data
      ?.filter((entry) => ids == null || ids.includes(String(entry.id)))
      ?.map((entry) => ({ ...entry, _technicalRoom: parents?.find((parent) => parent.id === entry.technicalRoom.id) }))
  }, [turnoutIds, swrResponse.data, parents])

  return useMemo(() => {
    return {
      ...swrResponse,
      data,
      isLoading: swrResponse.isLoading || parentsData.isLoading,
      isValidating: swrResponse.isValidating || parentsData.isValidating,
      mutate: async () => {
        await Promise.all([swrResponse.mutate(), parentsData.mutate()])
        return undefined
      },
    }
  }, [data, parentsData, swrResponse])
}

export const getTurnoutKey = (id: Parameters<typeof useTurnout>[0]): Key => {
  if (id == null) {
    return null
  }

  return ['useAsset', id]
}

export const useTurnout = (id: IdType | undefined | null): SWRResponse<Turnout> => {
  const swrResponse = useSWR(getTurnoutKey(id), () => {
    return TurnoutApiClient.read(id!)
  })

  const { data: parent, ...parentData } = useTechnicalRoom(swrResponse.data?.technicalRoom.id)

  const data = useMemo<Turnout | undefined>(() => {
    if (swrResponse.data == null) {
      return undefined
    }

    return { ...swrResponse.data, _technicalRoom: parent }
  }, [swrResponse.data, parent])

  return useMemo(() => {
    return {
      ...swrResponse,
      data,
      isLoading: swrResponse.isLoading || parentData.isLoading,
      isValidating: swrResponse.isValidating || parentData.isValidating,
      mutate: async () => {
        await Promise.all([swrResponse.mutate(), parentData.mutate()])
        return undefined
      },
    }
  }, [data, parentData, swrResponse])
}

export const getQueryTurnoutsKey = (opts: Parameters<typeof useQueryTurnouts>[0], parentId?: IdType): Key => {
  if (parentId == null) {
    return null
  }

  return ['useQueryAssets', parentId, JSON.stringify(opts)]
}

export const useQueryTurnouts = (opts?: Partial<QueryTurnoutFilterOpts> | null): SWRResponse<PagedTurnouts> => {
  const { data: mainProject } = useMainProject()
  const { data: parents, ...parentsData } = useTechnicalRooms()

  const swrResponse = useSWR(getQueryTurnoutsKey(opts, mainProject?.id), () => {
    return TurnoutApiClient.query(mainProject!.id, opts)
  })

  const data = useMemo<PagedTurnouts | undefined>(() => {
    if (swrResponse.data == null) {
      return undefined
    }

    return {
      ...swrResponse.data,
      content: swrResponse.data.content.map((entry) => ({
        ...entry,
        _technicalRoom: parents?.find((parent) => parent.id === entry.technicalRoom.id),
      })),
    }
  }, [swrResponse.data, parents])

  return useMemo(() => {
    return {
      ...swrResponse,
      data,
      isLoading: swrResponse.isLoading || parentsData.isLoading,
      isValidating: swrResponse.isValidating || parentsData.isValidating,
      mutate: async () => {
        await Promise.all([swrResponse.mutate(), parentsData.mutate()])
        return undefined
      },
    }
  }, [data, parentsData, swrResponse])
}

export const useCreateAsset = (): SWRMutationResponse<RawTurnout, ServiceError, BaseTurnoutWithPms, Key> => {
  const { cache, mutate } = useSWRConfig()

  return useSWRMutation('useCreateAsset', async (_, { arg }) => {

    const { data: createdTurnout } = await TurnoutApiClient.create(arg, arg.technicalRoom.id)

    for (let key of cache.keys()) {
      if (key.includes('useAsset') || key.includes('useQueryAssets') || key.includes('usePointMachines')) {
        mutate(key)
      }
    }

    return createdTurnout
  })
}

export const useUpdateTurnouts = (): SWRMutationResponse<RawTurnout[], ServiceError, RawTurnout[], Key> => {
  const { cache, mutate } = useSWRConfig()

  return useSWRMutation('useUpdateAssets', async (_, { arg }) => {
    const values = await Promise.all(arg.map((arg) => TurnoutApiClient.update(arg)))
    const results = values.map((value) => value.data)

    for (let key of cache.keys()) {
      if (key.includes('useAsset') || key.includes('useQueryAssets') || key.includes('usePointMachines') || key.includes('useTurnout')) {
        mutate(key)
      }
    }

    return results
  })
}

export const useDeleteTurnouts = (): SWRMutationResponse<void, ServiceError, IdType[], Key> => {
  const { cache, mutate } = useSWRConfig()

  return useSWRMutation('useDeleteAssets', async (_, { arg }) => {
    await Promise.all(arg.map((id) => TurnoutApiClient.delete(id)))

    for (let key of cache.keys()) {
      if (key.includes('useAsset') || key.includes('useQueryAssets') || key.includes('usePointMachines') || key.includes('useTurnout')) {
        mutate(key)
      }
    }
  })
}

export const getTurnoutStateStatisticsKey = (
  opts: Parameters<typeof useTurnoutStateStatistics>[0],
  parentId?: IdType,
): Key => {
  if (parentId == null) {
    return null
  }

  return ['useTurnoutStateStatistics', parentId, JSON.stringify(opts)]
}

export const useTurnoutStateStatistics = (
  opts?: Partial<TurnoutStateStatisticsFilterOpts> | null,
): SWRResponse<TurnoutStateStatistics> => {
  const { data: mainProject } = useMainProject()

  return useSWR(getTurnoutStateStatisticsKey(opts, mainProject?.id), () => {
    return TurnoutApiClient.stateStatistics(mainProject!.id, opts)
  })
}

export const getTurnoutHealthStatesKey = (
  turnoutId: Parameters<typeof useTurnoutHealthStates>[0],
  opts: Parameters<typeof useTurnoutHealthStates>[1],
): Key => {
  if (turnoutId == null || opts == null) {
    return null
  }

  return ['useTurnoutHealthStates', turnoutId, JSON.stringify(opts)]
}

export const useTurnoutHealthStates = (
  turnoutId: IdType | undefined | null,
  opts: Partial<TurnoutHealthStatesFilterOpts> | undefined | null,
): SWRResponse<HealthState[]> => {
  return useSWR(getTurnoutHealthStatesKey(turnoutId, opts), () => {
    return TurnoutApiClient.healthStates(turnoutId!, opts!)
  })
}

export const getTurnoutDisconnectPeriodsKey = (
  turnoutId: Parameters<typeof useTurnoutDisconnectPeriods>[0],
  opts: Parameters<typeof useTurnoutHealthStates>[1],
): Key => {
  if (turnoutId == null || opts == null) {
    return null
  }

  const { from, to, ...rest } = opts

  return ['useTurnoutDisconnectPeriods', turnoutId, JSON.stringify(rest)]
}

export const useTurnoutDisconnectPeriods = (
  turnoutId: IdType | undefined | null,
  opts: Partial<DateRangeFilterOpts> | undefined | null,
): SWRResponse<DisconnectPeriod[]> => {
  const swrResponse = useSWR(getTurnoutDisconnectPeriodsKey(turnoutId, opts), () => {
    return TurnoutApiClient.disconnectPeriod(turnoutId!)
  })

  const data = useMemo<DisconnectPeriod[] | undefined>(() => {
    return swrResponse.data
      ?.map((item): DisconnectPeriod | undefined => {
        const from = DateTime.fromISO(item.from)
        const to = DateTime.fromISO(item.to)

        if (opts?.from == null || opts.to == null || to.diff(opts.from, 'milliseconds').milliseconds < 0) {
          return undefined
        }

        const fromMs = from.toMillis()
        const optsMs = opts.from.toMillis()
        const maxMs = Math.max(fromMs, optsMs)
        const max = DateTime.fromMillis(maxMs)
        const maxStr = max.toISO()

        if (maxStr == null) {
          return undefined
        }

        return { ...item, from: maxStr }
      })
      .filter((d) => d != null) as DisconnectPeriod[] | undefined
  }, [opts, swrResponse.data])

  return useMemo(() => {
    return { ...swrResponse, data }
  }, [data, swrResponse])
}

export const getTurnoutStatisticsKey = (turnoutId: Parameters<typeof useTurnoutStatistics>[0]): Key => {
  if (turnoutId == null) {
    return null
  }

  return ['getTurnoutStatisticsKey', turnoutId]
}

export const useTurnoutStatistics = (turnoutId: IdType | undefined | null): SWRResponse<TurnoutStatistics> => {
  return useSWR(getTurnoutStatisticsKey(turnoutId), () => {
    return TurnoutApiClient.turnoutStatistics(turnoutId!)
  })
}

export const useTurnoutSearchFields = (filters: Filter[], collapsible = false): SearchField[] => {
  const { t } = useTranslation()

  const pointMachineSearchFields = usePointMachineSearchFields(filters, collapsible)

  return useMemo(() => {
    return [
      {
        collapsible: true,
        label: t('models.turnout.state'),
        options: [
          { label: t('models.turnout.state-healthy'), value: 'green' },
          { label: t('models.turnout.state-warning'), value: 'yellow' },
          { label: t('models.turnout.state-alert'), value: 'red' },
        ],
        path: 'operationState',
        type: 'select',
      },
      ...pointMachineSearchFields,
    ]
  }, [pointMachineSearchFields, t])
}
