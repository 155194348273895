import EventKpiTile from 'components/EventKpiTile'
import { useQueryPointMachineEvents } from 'hooks'
import { PointMachineStatistics } from 'models'
import { JSX } from 'react'
import { useTranslation } from 'react-i18next'

export interface PointMachineKpiBarProps {
  index: number
  pointMachine: PointMachineStatistics
}

export function PointMachineKpiBar({ index, pointMachine: pm }: PointMachineKpiBarProps): JSX.Element {
  const { data: pointMachineEvents, ...pointMachineEventsData } = useQueryPointMachineEvents({
    engineeringId: pm.engineeringId,
    size: 1,
  })
  const { t } = useTranslation()

  return (
    <div className="col-12" key={pm.id}>
      <div style={{ width: '100%' }}>
        <div className="row g-3">
          <div className="col-6 col-lg-3">
            <EventKpiTile size="small" title={t(`PM{{index}} name`, { index: index + 1 })} value={pm.name} />
          </div>

          <div className="col-6 col-lg-3">
            <EventKpiTile size="small" title={t('Fitness score')} precision={2} value={pm.fitnessScore ?? ' – '} />
          </div>

          <div className="col-6 col-lg-3">
            <EventKpiTile size="small" title={t('Position')} value={pm.position} />
          </div>

          <div className="col-6 col-lg-3">
            <EventKpiTile
              isLoading={pointMachineEventsData.isLoading}
              size="small"
              title={t('Nr of total turn events')}
              value={pointMachineEvents?.totalElements ?? ' – '}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
