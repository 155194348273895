import React, { ComponentProps, FC, ReactElement } from 'react'
import { IxIcon, IxMessageBar } from '@siemens/ix-react'

interface MessageBarProps extends Omit<ComponentProps<typeof IxMessageBar>, 'type'> {
  type?: 'danger' | 'warning' | 'info' | 'success' | undefined
}

/**
 * Custom version of the IxMessageBar which is not supporting 'success'
 * If you pass type='success' an individual version is rendered.
 * Otherwise, the original IxMessageBar is used.
 * @param props MessageBarProps
 * @constructor
 */
const MessageBar: FC<MessageBarProps> = (props): ReactElement => {
  if(props.type === 'success') {
    return (
      <div style={{margin: '0.5rem 0rem 1rem 0rem'}}>
        <div className="message-container info" role="alert"
          style={{
            border: 'solid var(--theme-message-bar--border-thickness) var(--theme-color-success)',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-start',
            flexWrap: 'nowrap',
            justifyContent: 'space-between',
            minHeight: '3.375rem',
            padding: 'calc(0.75rem - var(--theme-message-bar--border-thickness)) 0.75rem calc(0.75rem - var(--theme-message-bar--border-thickness)) 1rem',
            borderRadius: 'var(--theme-message-bar--border-radius)',
            backgroundColor: 'var(--theme-messagebar--background)'
          }}
        >
          <IxIcon name={'success'}
            style={{
              color: 'var(--theme-color-success)',
              marginTop: '0.25rem'
            }}
          />
          <div className="message-content"
            style={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              flexGrow: '1',
              alignSelf: 'center',
              minHeight: '1.25rem',
              padding: '0 1rem',
              fontWeight: 'normal',
              whiteSpace: 'normal'
            }}
          >
            {props.children}
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <IxMessageBar {...props} type={props.type} />
    )
  }
}

export default MessageBar
