import { SWRResponse, useSWR } from 'lib/swr'
import { IdType } from 'models'
import { Key } from 'swr'
import { SensorMeasurementApiClient, SensorMeasurementFilterOpts } from '../api/sensor-measurements'
import { SensorMeasurements } from '../models/sensor-measurements'

export const getSensorMeasurementsKey = (
  parentId: Parameters<typeof useSensorMeasurements>[0],
  opts: Parameters<typeof useSensorMeasurements>[1],
): Key => {
  if (parentId == null || opts == null) {
    return null
  }

  return ['useSensorMeasurements', parentId, ...JSON.stringify(opts)]
}

export const useSensorMeasurements = (
  parentId: IdType | undefined | null,
  opts?: Partial<SensorMeasurementFilterOpts> | null,
): SWRResponse<SensorMeasurements> => {
  return useSWR(getSensorMeasurementsKey(parentId, opts), () => {
    return SensorMeasurementApiClient.measurements(parentId!, opts)
  })
}
