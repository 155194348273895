import * as t from 'io-ts'
import { BaseModelCodec } from './base'

export const AnalyticsTypeTurnOver = 'turntime_expertsystem'
export const AnalyticsTypeMeanPower = 'meanpower_expertsystem'
export const AnalyticsTypePowerCurve = 'powercurve_aimodel'

export const AnalyticsTypeNameCodec = t.union([
  t.literal(AnalyticsTypeTurnOver),
  t.literal(AnalyticsTypeMeanPower),
  t.literal(AnalyticsTypePowerCurve)
])
export type AnalyticsTypeName = t.TypeOf<typeof AnalyticsTypesCodec>
export const BaseAnalyticsTypeNameCodec = t.type({
  name: AnalyticsTypeNameCodec,
})

export const AnalyticsTypeCodec = t.intersection([BaseModelCodec, BaseAnalyticsTypeNameCodec])
export const AnalyticsTypesCodec = t.array(AnalyticsTypeCodec)
export type AnalyticsType = t.TypeOf<typeof AnalyticsTypeCodec>
export type AnalyticsTypes = t.TypeOf<typeof AnalyticsTypesCodec>
